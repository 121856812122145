<template>
  <header
    class="fixed z-20 w-full max-w-screen-xl px-5 transform -translate-x-1/2 xl:px-0 top-5 left-1/2"
  >
    <div class="relative hidden w-full sm:block">
      <div
        class="relative z-10 flex items-stretch w-full sm:border sm:bg-black sm:bg-opacity-50 sm:border-gray"
      >
        <ol class="flex items-stretch flex-1">
          <li class="w-1/2 border-r md:w-1/5 border-gray">
            <router-link class="block w-full text-center sm:py-1" to="/">
              <img
                src="/img/logo.png"
                alt="Bullking 牛王排氣管"
                width="112"
                height="42"
                class="block mx-auto w-28"
              />
            </router-link>
          </li>
          <li class="block w-1/2 border-r md:hidden border-gray">
            <router-link
              class="flex items-center justify-center w-full h-full bg-opacity-50 text-gray hover:underline bg-light-red"
              to="/store"
            >
              立即購買
            </router-link>
          </li>
          <li
            class="hidden w-1/5 border-r border-gray md:block"
            v-for="(item, item_index) in menu_list"
            :key="`menu_item_${item_index}`"
          >
            <router-link
              :class="
                item_index == 0
                  ? 'bg-opacity-50 bg-light-red'
                  : 'hover:bg-opacity-50 hover:bg-light-red'
              "
              class="flex items-center justify-center w-full h-full font-bold text-center transition-all duration-300 text-gray hover:underline"
              :to="item.link"
            >
              {{ item.title }}
            </router-link>
          </li>
        </ol>
        <a
          target="_blank"
          href="https://zh-tw.facebook.com/YongXinExhaust/"
          aria-label="牛王排氣管 Facebook"
          class="items-center justify-center hidden w-12 h-12 border-r border-gray hover:bg-light-red hover:bg-opacity-50 sm:flex"
        >
          <span class="block text-4xl text-white icon-facebook"></span>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/bullking_exhaust/"
          aria-label="牛王排氣管 Instagram"
          class="items-center justify-center hidden w-12 h-12 sm:flex hover:bg-red hover:bg-opacity-50"
        >
          <span class="block text-4xl text-white icon-instagram"></span>
        </a>
        <button
          @click="$store.commit('SetMenu', true)"
          class="flex items-center justify-center w-12 h-12 border-l md:hidden hover:bg-light-red hover:bg-opacity-50 border-gray"
        >
          <span class="block text-4xl text-white icon-menu"></span>
        </button>
      </div>
      <div
        class="absolute bottom-0 hidden w-full h-1 pl-1 overflow-hidden translate-y-full -left-1 sm:block"
      >
        <div class="w-full h-full bg-opacity-40 bg-gray"></div>
      </div>
      <div
        class="absolute hidden w-1 h-full overflow-hidden -translate-x-full top-1 -left-0 sm:block"
      >
        <div class="w-full h-full bg-opacity-40 bg-gray"></div>
      </div>
    </div>
    <div class="relative flex items-center justify-between w-full sm:hidden">
      <router-link class="block" to="/">
        <img
          alt="Bullking 牛王排氣管"
          src="/img/logo.png"
          width="144"
          height="53"
          class="block w-36"
        />
      </router-link>
      <div class="flex items-stretch justify-end">
        <router-link
          to="/store"
          class="relative flex items-center justify-center px-3 py-2 mr-4 text-sm font-bold text-white underline border border-white bg-opacity-90 bg-red"
        >
          立即購買
          <div
            class="absolute bottom-0 block w-full h-1 pl-1 overflow-hidden translate-y-full -left-1"
          >
            <div class="w-full h-full bg-opacity-40 bg-gray"></div>
          </div>
          <div
            class="absolute block w-1 h-full overflow-hidden -translate-x-full top-1 -left-0"
          >
            <div class="w-full h-full bg-opacity-40 bg-gray"></div>
          </div>
        </router-link>

        <button
          @click="$store.commit('SetMenu', true)"
          class="relative flex items-center justify-center transition-all duration-300 transform bg-black border border-white bg-opacity-60 w-9 h-9 md:hidden hover:bg-light-red hover:bg-opacity-50"
        >
          <span class="block text-2xl text-white icon-menu"></span>
          <div
            class="absolute bottom-0 block w-full h-1 pl-1 overflow-hidden translate-y-full -left-1"
          >
            <div
              class="w-full h-full transition-all duration-300 transform bg-opacity-40 bg-gray"
            ></div>
          </div>
          <div
            class="absolute block w-1 h-full overflow-hidden -translate-x-full top-1 -left-0"
          >
            <div
              class="w-full h-full transition-all duration-300 bg-opacity-40 bg-gray"
            ></div>
          </div>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MainHeader',
  data() {
    return {
      menu_list: [
        {
          title: '立即購買',
          link: '/store',
        },
        {
          title: '產品列表',
          link: '/model',
        },
        {
          title: '經銷列表',
          link: '/find_us',
        },
        {
          title: '關於我們',
          link: '/about',
        },
      ],
    };
  },
};
</script>
