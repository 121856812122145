<template>
  <footer ref="MainContent" class="relative z-10 w-full overflow-hidden">
    <div
      data-text-box
      class="flex h-[140px] py-7 w-auto opacity-50 relative z-10 transform -translate-y-1/3"
    >
      <div
        class="h-full px-5"
        v-for="text in 10"
        :key="`text_carousel_top_${text}`"
      >
        <img
          src="/img/home_carousel/bullking_text.webp"
          alt="Bullking 牛王排氣管"
          width="795"
          height="84"
          class="inline-block w-auto h-full max-w-screen-2xl"
        />
      </div>
    </div>
    <div class="w-full max-w-screen-xl px-5 mx-auto py-14 xl:px-0 sm:px-14">
      <div class="flex items-center justify-between w-full mb-10 md:mb-0">
        <router-link to="/">
          <img
            src="/img/logo.png"
            alt="Bullking 牛王排氣管"
            width="232"
            height="85"
            class="block md:w-[232px] w-[200px]"
          />
        </router-link>
        <i class="w-full ml-5 opacity-20 flex-1 block h-[1px] bg-gray"></i>
      </div>
      <div class="w-full md:pl-[232px] flex flex-wrap justify-between mb-10">
        <div class="w-full mb-10 md:w-1/2 md:order-1">
          <h4 class="mb-3 font-panchang text-gray text-opacity-40">OFFICE</h4>
          <div class="flex mb-2 text-gray">
            <label class="block mr-5">公司地址</label>
            <p class="font-panchang">彰化縣員林市中央路408號</p>
          </div>
          <div class="flex mb-2 text-gray">
            <label class="block mr-5">公司電話</label>
            <a href="tel://04-2471-9799" target="_blank" class="font-panchang"
              >04-2471-9799</a
            >
          </div>
          <div class="flex mb-2 text-gray">
            <label class="block mr-5">公司傳真</label>
            <p class="font-panchang">04-2471-9499</p>
          </div>
          <div class="flex mb-2 text-gray">
            <label class="block mr-5">營業時間</label>
            <p class="font-panchang">09:00 - 18:00</p>
          </div>
        </div>

        <div class="flex justify-end order-3 w-1/2 mb-10 md:order-2">
          <MBMoreBtn text="Page Top" @click="ScrollToTop" />
        </div>

        <div class="order-2 w-1/2 md:order-3">
          <h4 class="mb-3 font-panchang text-gray text-opacity-40">FOLLOW</h4>
          <a
            target="_blank"
            href="https://zh-tw.facebook.com/YongXinExhaust/"
            aria-label="牛王排氣管 Facebook"
            class="inline-flex items-center justify-center w-10 h-10 mr-5 transition-all duration-300 rounded-lg bg-gray hover:bg-red"
          >
            <span class="block text-4xl text-light-black icon-facebook"></span>
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/bullking_exhaust/"
            aria-label="牛王排氣管 Instagram"
            class="inline-flex items-center justify-center w-10 h-10 transition-all duration-300 rounded-lg bg-gray hover:bg-red"
          >
            <span class="block text-4xl text-light-black icon-instagram"></span>
          </a>
        </div>

        <div class="flex order-4 w-full md:justify-end md:w-1/2">
          <div>
            <h4 class="w-full mb-3 font-panchang text-gray text-opacity-40">
              MENU
            </h4>
            <router-link to="/model" class="inline-block mr-5 text-gray">
              產品列表
            </router-link>
            <router-link to="/news" class="inline-block mr-5 text-gray">
              最新消息
            </router-link>
            <router-link to="/about" class="inline-block mr-5 text-gray">
              關於我們
            </router-link>
            <router-link to="/find_us" class="inline-block mr-5 text-gray">
              經銷據點
            </router-link>
            <router-link to="/contact" class="inline-block text-gray">
              聯絡我們
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col justify-between w-full pt-2 border-t md:items-center md:flex-row border-gray border-opacity-20"
      >
        <p class="mb-2 text-sm text-dark-gray md:mb-0">
          Copyright © 2014-2023 Bullking Inc. 著作權所有，並保留一切權利。
        </p>
        <p class="text-sm text-dark-gray">
          <router-link class="text-white underline" to="/ship_doc"
            >購買須知</router-link
          >
          /
          <router-link class="text-white underline" to="/privacy_policy"
            >隱私權政策</router-link
          >
          ，網頁設計：<a
            class="text-light-red"
            href="https://www.yongxin-design.com"
            target="_blank"
            >泳欣事業有限公司</a
          >
        </p>
      </div>
    </div>
    <div
      data-text-box
      class="flex h-[140px] py-7 w-auto opacity-50 relative z-10 transform translate-y-1/3"
    >
      <div
        class="h-full px-5"
        v-for="text in 10"
        :key="`text_carousel_top_${text}`"
      >
        <img
          src="/img/home_carousel/bullking_text.webp"
          alt="Bullking 牛王排氣管"
          width="795"
          height="84"
          class="inline-block w-auto h-full max-w-screen-2xl"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import MBMoreBtn from '@/components/footer/MoreButton@sm.vue';
import { footer_animation } from '@/gsap/footer/footer';
export default {
  name: 'MainFotoer',
  components: {
    MBMoreBtn,
  },
  data() {
    return {
      footer_animation: null,
    };
  },
  methods: {
    ScrollToTop() {
      document.querySelector('#app').scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  mounted() {
    this.footer_animation = new footer_animation(this.$refs.MainContent);
  },
};
</script>
