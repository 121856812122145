<template>
  <button
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="$emit('click')"
    class="relative flex items-center justify-center sm:w-[125px] sm:h-[125px] w-[100px] h-[100px]"
  >
    <div class="relative flex items-center justify-center pb-3">
      <span
        :class="
          hover
            ? ' text-light-red text-opacity-80'
            : ' text-light-red text-opacity-100'
        "
        class="z-[1] text-8xl leading-none mx-auto transition-all duration-300 transform icon-arrow_up"
      ></span>

      <p
        :class="hover ? 'text-red' : 'text-gray text-opacity-80'"
        class="absolute left-0 z-20 w-full text-xs font-bold text-center transition-all duration-300 bottom-2 sm:text-sm font-panchang"
      >
        {{ text }}
      </p>
    </div>

    <div
      class="absolute top-0 left-0 z-0 w-full transition-all duration-300 bg-repeat aspect-square"
    >
      <div :class="hover ? 'opacity-0' : 'opacity-100'">
        <div
          class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat button_line_bg default"
        ></div>
      </div>
      <div :class="hover ? 'opacity-100' : 'opacity-0'">
        <div
          class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat bg_line_animate button_line_bg dark"
        ></div>
      </div>
    </div>
  </button>
</template>

<style scoped>
.bg_line_animate {
  animation: line_animation 1s infinite linear;
}

@keyframes line_animation {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 48px;
  }
}
</style>
<script>
export default {
  name: "MoreButton",
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
