var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"fixed z-20 w-full max-w-screen-xl px-5 transform -translate-x-1/2 xl:px-0 top-5 left-1/2"},[_c('div',{staticClass:"relative hidden w-full sm:block"},[_c('div',{staticClass:"relative z-10 flex items-stretch w-full sm:border sm:bg-black sm:bg-opacity-50 sm:border-gray"},[_c('ol',{staticClass:"flex items-stretch flex-1"},[_c('li',{staticClass:"w-1/2 border-r md:w-1/5 border-gray"},[_c('router-link',{staticClass:"block w-full text-center sm:py-1",attrs:{"to":"/"}},[_c('img',{staticClass:"block mx-auto w-28",attrs:{"src":"/img/logo.png","alt":"Bullking 牛王排氣管","width":"112","height":"42"}})])],1),_c('li',{staticClass:"block w-1/2 border-r md:hidden border-gray"},[_c('router-link',{staticClass:"flex items-center justify-center w-full h-full bg-opacity-50 text-gray hover:underline bg-light-red",attrs:{"to":"/store"}},[_vm._v(" 立即購買 ")])],1),_vm._l((_vm.menu_list),function(item,item_index){return _c('li',{key:`menu_item_${item_index}`,staticClass:"hidden w-1/5 border-r border-gray md:block"},[_c('router-link',{staticClass:"flex items-center justify-center w-full h-full font-bold text-center transition-all duration-300 text-gray hover:underline",class:item_index == 0
                ? 'bg-opacity-50 bg-light-red'
                : 'hover:bg-opacity-50 hover:bg-light-red',attrs:{"to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)})],2),_vm._m(0),_vm._m(1),_c('button',{staticClass:"flex items-center justify-center w-12 h-12 border-l md:hidden hover:bg-light-red hover:bg-opacity-50 border-gray",on:{"click":function($event){return _vm.$store.commit('SetMenu', true)}}},[_c('span',{staticClass:"block text-4xl text-white icon-menu"})])]),_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"relative flex items-center justify-between w-full sm:hidden"},[_c('router-link',{staticClass:"block",attrs:{"to":"/"}},[_c('img',{staticClass:"block w-36",attrs:{"alt":"Bullking 牛王排氣管","src":"/img/logo.png","width":"144","height":"53"}})]),_c('div',{staticClass:"flex items-stretch justify-end"},[_c('router-link',{staticClass:"relative flex items-center justify-center px-3 py-2 mr-4 text-sm font-bold text-white underline border border-white bg-opacity-90 bg-red",attrs:{"to":"/store"}},[_vm._v(" 立即購買 "),_c('div',{staticClass:"absolute bottom-0 block w-full h-1 pl-1 overflow-hidden translate-y-full -left-1"},[_c('div',{staticClass:"w-full h-full bg-opacity-40 bg-gray"})]),_c('div',{staticClass:"absolute block w-1 h-full overflow-hidden -translate-x-full top-1 -left-0"},[_c('div',{staticClass:"w-full h-full bg-opacity-40 bg-gray"})])]),_c('button',{staticClass:"relative flex items-center justify-center transition-all duration-300 transform bg-black border border-white bg-opacity-60 w-9 h-9 md:hidden hover:bg-light-red hover:bg-opacity-50",on:{"click":function($event){return _vm.$store.commit('SetMenu', true)}}},[_c('span',{staticClass:"block text-2xl text-white icon-menu"}),_vm._m(4),_vm._m(5)])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"items-center justify-center hidden w-12 h-12 border-r border-gray hover:bg-light-red hover:bg-opacity-50 sm:flex",attrs:{"target":"_blank","href":"https://zh-tw.facebook.com/YongXinExhaust/","aria-label":"牛王排氣管 Facebook"}},[_c('span',{staticClass:"block text-4xl text-white icon-facebook"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"items-center justify-center hidden w-12 h-12 sm:flex hover:bg-red hover:bg-opacity-50",attrs:{"target":"_blank","href":"https://www.instagram.com/bullking_exhaust/","aria-label":"牛王排氣管 Instagram"}},[_c('span',{staticClass:"block text-4xl text-white icon-instagram"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute bottom-0 hidden w-full h-1 pl-1 overflow-hidden translate-y-full -left-1 sm:block"},[_c('div',{staticClass:"w-full h-full bg-opacity-40 bg-gray"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute hidden w-1 h-full overflow-hidden -translate-x-full top-1 -left-0 sm:block"},[_c('div',{staticClass:"w-full h-full bg-opacity-40 bg-gray"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute bottom-0 block w-full h-1 pl-1 overflow-hidden translate-y-full -left-1"},[_c('div',{staticClass:"w-full h-full transition-all duration-300 transform bg-opacity-40 bg-gray"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute block w-1 h-full overflow-hidden -translate-x-full top-1 -left-0"},[_c('div',{staticClass:"w-full h-full transition-all duration-300 bg-opacity-40 bg-gray"})])
}]

export { render, staticRenderFns }