<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl mx-auto"
  >
    <div
      class="relative z-10 flex flex-wrap items-stretch w-full px-16 py-24 xl:px-0"
    >
      <div class="w-full mb-28">
        <i data-title-line class="w-10 h-[1px] bg-red block mb-4"></i>
        <h3 class="overflow-hidden">
          <span data-title class="block text-2xl font-bold text-white"
            >排氣管一覽</span
          >
        </h3>
      </div>

      <div
        class="relative flex items-stretch justify-start overflow-hidden whitespace-nowrap"
      >
        <article
          data-product-card
          v-for="(item, item_index) in product_data"
          :key="`product_${item_index}`"
          class="flex items-stretch justify-between flex-shrink-0 w-full overflow-hidden whitespace-normal"
        >
          <div data-content-box>
            <div class="flex items-end mb-4">
              <span
                class="px-4 py-[2px] mr-5 font-bold text-white bg-red flex-shrink-0 block"
              >
                {{ item.tag }}
              </span>
              <span
                class="block text-2xl leading-none font-panchang text-gray opacity-20"
              >
                BULLKING
              </span>
            </div>
            <h2
              class="font-black lg:text-[120px] text-[80px] mb-5 font-panchang text-gray leading-none"
              v-html="item.title"
            ></h2>
            <h3 class="mb-8 text-3xl font-bold lg:text-4xl text-gray">
              {{ item.sub_title }}
            </h3>
            <p
              class="mb-24 text-sm font-bold lg:text-base text-gray lg:max-w-[400px] max-w-[280px]"
              v-html="item.intro"
            ></p>
            <MoreButton
              text="立即購買"
              :link="item.link"
              class="lg:w-96 w-[280px]"
            />
          </div>
          <div class="relative flex items-start justify-center flex-1 w-full">
            <div class="relative z-10 flex items-center justify-center w-full">
              <div
                data-product-image
                class="absolute z-10 w-4/5 top-1/2 left-1/2"
              >
                <img
                  :alt="item.title + ' 牛王' + item.sub_title"
                  :src="item.image"
                  width="794"
                  height="559"
                  class="w-full drop-shadow-xl"
                />
              </div>
              <div class="relative z-0 w-1/2">
                <div
                  data-background-image
                  class="relative w-full overflow-hidden"
                >
                  <img
                    :alt="item.title + ' 牛王' + item.sub_title"
                    :src="item.background_image"
                    width="440"
                    height="553"
                    class="relative z-10 block w-full opacity-50 contrast-125 brightness-50"
                  />
                </div>
                <div
                  data-background-border
                  class="absolute top-0 bottom-0 left-0 right-0 transform border-2 border-black opacity-20"
                ></div>
                <!-- -translate-x-4 translate-y-4 -->
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>

    <ol
      data-button-list
      class="absolute z-10 transform -translate-y-1/2 xl:right-0 right-10 top-1/2"
    >
      <li
        v-for="(item, item_index) in product_data"
        :key="`product_button_${item_index}`"
        class="mb-5"
      >
        <div
          @mouseenter="hover_index = item_index"
          @mouseleave="hover_index = -1"
          class="relative"
        >
          <button
            @click="ChangeCard(item_index)"
            :class="
              hover_index == item_index || active_card == item_index
                ? 'bg-red border-black'
                : 'bg-transparent border-gray'
            "
            class="relative transition-all duration-300 border"
          >
            <img
              :alt="item.title + item.sub_title"
              :src="item.button_image"
              width="56"
              height="56"
              class="relative z-10 w-14 h-14"
            />
          </button>
          <div
            class="absolute bottom-0 left-0 w-full h-1 transform -translate-x-1 z-[1]"
          >
            <i
              :class="
                hover_index == item_index || active_card == item_index
                  ? 'border-black bg-black'
                  : 'border-gray bg-transparent'
              "
              class="block w-full h-full transition-all duration-300 border-b border-l border-r"
            ></i>
          </div>
          <div
            class="absolute top-0 left-0 w-1 h-full pb-1 transform -translate-x-[100%] translate-y-1"
          >
            <i
              :class="
                hover_index == item_index || active_card == item_index
                  ? 'border-black bg-black'
                  : 'border-gray bg-transparent'
              "
              class="block w-full h-full transition-all duration-300 border-t border-l"
            ></i>
          </div>
        </div>
      </li>
    </ol>

    <div
      class="absolute top-0 bottom-0 left-0 flex items-start w-1/3 pb-20 overflow-hidden lg:justify-end"
    >
      <img
        alt="牛王排氣管商品"
        data-bg-text
        src="/img/home_products/background_text.svg"
        width="97"
        height="885"
        class="block h-full opacity-10"
      />
    </div>
    <div
      data-bg-line
      class="absolute top-0 right-0 z-0 object-cover w-full h-full bg-repeat opacity-50 md:w-2/3 button_line_bg dark"
      style="background-size: 900px"
    ></div>
  </section>
</template>

<script>
import { products_animation } from "@/gsap/home/pc_products";
import MoreButton from "@/components/home/products/MoreButton@lg.vue";
import product_data from "@/assets/products.json";
export default {
  name: "ProductSection",
  components: {
    MoreButton,
  },
  data() {
    return {
      product_data: product_data,
      products_animation: null,
      active_card: 0,
      hover_index: -1,
    };
  },
  methods: {
    ChangeCard(index) {
      if (index != this.active_card) {
        this.active_card = index;
        this.products_animation.changeCard(index);
      }
    },

    SetGsap() {
      this.products_animation = new products_animation(this.$refs.MainContent);
    },
  },
};
</script>
