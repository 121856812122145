<template>
  <div id="app" class="relative overflow-x-hidden">
    <MainLoading />
    <MainHeader />
    <MainMenu />
    <MainDialog />
    <EventImageDialog />

    <div class="relative w-full min-h-screen">
      <router-view />
    </div>

    <BuyActionFooter />
    <ContactFooter />
    <MainFooter />

    <div class="background_box"></div>
  </div>
</template>

<style src="@/assets/css/output.css"></style>

<script>
import MainHeader from "@/components/MainHeader.vue";
import BuyActionFooter from "@/components/BuyActionFooter.vue";
import ContactFooter from "@/components/ContactFooter.vue";
import MainFooter from "@/components/MainFooter.vue";
import MainMenu from "@/components/MainMenu.vue";
import MainDialog from "@/components/MainDialog.vue";
import MainLoading from "@/components/MainLoading.vue";
import { ViewPage } from "@/api/page";
import { ViewContent } from "@/common/pixel_methods";
import EventImageDialog from "./components/EventImageDialog.vue";
export default {
  name: "app",
  components: {
    MainHeader,
    BuyActionFooter,
    ContactFooter,
    MainFooter,
    MainMenu,
    MainDialog,
    MainLoading,
    EventImageDialog,
  },
  watch: {
    $route() {
      ViewPage(this.$route.path);
      ViewContent();
    },
  },
  mounted() {
    ViewPage(this.$route.path);
    ViewContent();
  },
};
</script>
