import { render, staticRenderFns } from "./MoreButton@lg.vue?vue&type=template&id=24c7e484&scoped=true&"
import script from "./MoreButton@lg.vue?vue&type=script&lang=js&"
export * from "./MoreButton@lg.vue?vue&type=script&lang=js&"
import style0 from "./MoreButton@lg.vue?vue&type=style&index=0&id=24c7e484&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c7e484",
  null
  
)

export default component.exports