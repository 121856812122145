import { render, staticRenderFns } from "./MainDialog.vue?vue&type=template&id=c5a1117c&scoped=true&"
import script from "./MainDialog.vue?vue&type=script&lang=js&"
export * from "./MainDialog.vue?vue&type=script&lang=js&"
import style0 from "./MainDialog.vue?vue&type=style&index=0&id=c5a1117c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a1117c",
  null
  
)

export default component.exports