const option_name = {
  case_option: "防燙蓋設定",
  front_option: "前段設定",
  power_option: "內部設定",
  ring_option: "束環設定",
  hanger_option: "腳架設定",
  paint_option: "烤黑設定",
};

export function AddPaymentInfo(shop_cart) {
  let option_text = "";
  shop_cart.options.forEach((item, item_index) => {
    item_index != 0 ? (option_text += "/") : "";
    option_text += option_name[item.type] + ":" + item.value;
  });

  const product_content = [
    {
      id: shop_cart.product.code,
      quantity: 1,
      price: parseInt(shop_cart.total_price),
      option: option_text,
    },
  ];

  window.fbq("track", "AddPaymentInfo", {
    content_category: "結帳頁面",
    content_type: "products",
    content_ids: [shop_cart.product.code],
    contents: product_content,
    currency: "TWD",
    value: 0,
  });
}

export function InitiateCheckout(shop_cart) {
  let option_text = "";
  shop_cart.options.forEach((item, item_index) => {
    item_index != 0 ? (option_text += "/") : "";
    option_text += option_name[item.type] + ":" + item.value;
  });

  const product_content = [
    {
      id: shop_cart.product.code,
      quantity: 1,
      price: parseInt(shop_cart.total_price),
      option: option_text,
    },
  ];

  window.fbq("track", "InitiateCheckout", {
    content_category: "結帳頁面",
    content_ids: [shop_cart.product.code],
    contents: product_content,
    num_items: 1,
    currency: "TWD",
    value: 0,
  });
}

export function Purchase(shop_cart) {
  let option_text = "";
  shop_cart.options.forEach((item, item_index) => {
    item_index != 0 ? (option_text += "/") : "";
    option_text += option_name[item.type] + ":" + item.value;
  });

  const product_content = [
    {
      id: shop_cart.product.code,
      quantity: 1,
      price: parseInt(shop_cart.total_price),
      option: option_text,
    },
  ];

  window.fbq(
    "track",
    "Purchase",
    {
      content_ids: [shop_cart.product.code],
      content_name: "建立訂單",
      content_type: "product",
      contents: product_content,
      currency: "TWD",
      num_items: 1,
      value: parseInt(shop_cart.total_price),
    }
    // { eventID: trade_data.TradeID }
  );
}

export function ViewContent() {
  window.fbq("track", "ViewContent");
}

export function FindLocation(area_title) {
  window.fbq("track", "FindLocation", {
    area: area_title,
    value: 0,
  });
}

//FindLocation

//Lead
//AddToWishlist
//Contact
//FindLocation
//Schedule
//StartTrial
//SubmitApplication
//Subscribe
