<template>
  <div
    ref="MainContent"
    class="fixed top-0 z-30 flex items-center justify-center w-full h-full left-full"
  >
    <div
      data-dialog-box
      class="w-11/12 max-w-[480px] sm:px-24 px-10 py-24 bg-light-black relative z-10"
    >
      <div class="relative z-10">
        <div
          class="mb-5 font-medium tracking-widest text-center text-gray"
          v-html="msg"
        ></div>

        <button
          @mouseenter="close_btn_hover = true"
          @mouseleave="close_btn_hover = false"
          @click="Close"
          class="relative block w-full py-3"
        >
          <p class="relative z-10 font-medium tracking-widest text-white">
            關閉
          </p>
          <div class="absolute top-0 left-0 z-0 w-full h-full">
            <div
              class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat button_line_bg default"
              :class="close_btn_hover ? 'opacity-0' : 'opacity-100'"
            ></div>
            <div
              class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat bg_line_animate button_line_bg dark"
              :class="close_btn_hover ? 'opacity-100' : 'opacity-0'"
            ></div>
          </div>
        </button>
      </div>
      <div
        class="absolute top-0 left-0 z-0 w-full h-full noise_bg opacity-40"
      ></div>
    </div>
    <div
      data-dialog-bg
      class="absolute top-0 left-0 z-0 w-full h-full bg-black bg-opacity-60"
    ></div>
  </div>
</template>
<style scoped>
.bg_line_animate {
  animation: line_animation 1s infinite linear;
}

@keyframes line_animation {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 48px;
  }
}
</style>

<script>
import { dialog_animation } from "@/gsap/dialog";
export default {
  name: "MainDialog",
  data() {
    return {
      close_btn_hover: false,
      dialog_animation: null,
    };
  },
  methods: {
    Close() {
      this.$store.commit("SetMainDialog", { msg: this.msg, action: false });
    },
  },
  watch: {
    dialog() {
      this.dialog
        ? this.dialog_animation.open()
        : this.dialog_animation.close();
    },
  },
  computed: {
    dialog() {
      return this.$store.state.main_dialog.status;
    },
    msg() {
      return this.$store.state.main_dialog.msg;
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
  },
};
</script>
