import { horizon_scroll } from '@/gsap/horizon_scroll';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
//ScrollTrigger.normalizeScroll(true);
gsap.registerPlugin(ScrollTrigger);

export class contact_animation extends horizon_scroll {
  constructor(el) {
    super();
    this.el = el;
    this.text_box = this.el.querySelectorAll('[data-text-box]');
    this.background = this.el.querySelectorAll('[data-background-image]');
    this.card_box = this.el.querySelectorAll('[data-card]');
    this.card = this.el.querySelectorAll('[data-card] a');
    this.timeline = null;
    this.text_timelline = null;
    this.background_timeline = null;
    this.init();

    // window.innerWidth > 768 ? this.setTextCarousel() : '';
    this.setTextCarousel();
    // this.setScrub();
  }

  init() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 60%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline
      .fromTo(
        this.card_box,
        {
          opacity: 0,
          y: '30px',
        },
        {
          opacity: 1,
          y: '0px',
        },
        'same'
      )
      .fromTo(
        this.card,
        {
          x: '-14px',
          y: '14px',
        },
        {
          x: '0px',
          y: '0px',
        },
        'same'
      );
  }

  setScrub() {
    this.background_timeline != null ? this.background_timeline.kill() : '';
    this.background_timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });

    this.background_timeline.fromTo(
      this.background,
      {
        scale: 1.5,
        x: '-10%',
      },
      {
        scale: 1,
        x: '0%',
      }
    );
  }

  setTextCarousel() {
    this.text_timelline != null ? this.text_timelline.kill() : '';
    this.text_timelline = gsap.timeline();
    const top_text = this.text_box[0].querySelectorAll('div');

    this.text_timelline = this.setScrollForward(top_text, 1);
  }
}
