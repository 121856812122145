<template>
  <div
    ref="MainContent"
    class="fixed top-0 bottom-0 z-40 block w-full left-full md:hidden"
  >
    <div class="relative z-10 w-full p-5">
      <div data-header class="flex items-center justify-between w-full">
        <router-link to="/">
          <img
            src="/img/logo.png"
            alt="Bullking 牛王排氣管"
            width="200"
            height="74"
            class="sm:w-[200px] w-[140px]"
          />
        </router-link>
        <CloseButton @click="$store.commit('SetMenu', false)" />
      </div>
      <ol
        class="flex flex-wrap items-stretch justify-start pt-10 -mx-5 sm:pt-20"
      >
        <li
          data-link
          class="w-full px-5 mb-6 sm:mb-10 sm:w-1/2"
          v-for="(item, item_index) in menu_list"
          :key="`menu_item_${item_index}`"
        >
          <LinkButton
            @click="$store.commit('SetMenu', false)"
            :text="item.title"
            :link="item.link"
            :sub_title="item.sub_title"
          />
        </li>
      </ol>
    </div>

    <div
      data-footer
      class="absolute bottom-5 sm:left-auto left-5 sm:right-5 z-[2] flex sm:flex-col justify-center items-center"
    >
      <i
        class="sm:w-[1px] sm:h-10 w-10 sm:mr-0 mr-3 h-[1px] bg-white block sm:mb-5"
      ></i>
      <p
        class="hidden mb-4 mr-0 text-sm text-white sm:text-base vertical-lr font-panchang sm:block"
      >
        Follow
      </p>
      <p
        class="block mr-3 text-sm text-white sm:text-base sm:mb-4 sm:mr-0 font-panchang sm:hidden"
      >
        Follow
      </p>
      <a
        class="block"
        target="_blank"
        href="https://zh-tw.facebook.com/YongXinExhaust/"
      >
        <span class="text-4xl leading-none text-gray icon-facebook"></span>
      </a>
      <a
        class="block"
        target="_blank"
        href="https://www.instagram.com/bullking_exhaust/"
      >
        <span class="text-4xl leading-none text-gray icon-instagram"></span>
      </a>
    </div>

    <div
      data-background
      class="absolute top-0 left-0 z-[1] w-full h-full bg-light-gray"
    >
      <div
        class="absolute top-0 left-0 w-full h-full opacity-100 bg-light-black noise_bg"
      ></div>
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/main_menu/CloseButton.vue';
import LinkButton from '@/components/main_menu/LinkButton.vue';
import { main_menu_animation } from '@/gsap/main_menu';
export default {
  name: 'MainMenu',
  components: {
    CloseButton,
    LinkButton,
  },
  data() {
    return {
      menu_list: [
        {
          title: '首頁',
          sub_title: 'Homepage',
          link: '/',
        },
        {
          title: '訂製排氣管',
          sub_title: 'Customize',
          link: '/store',
        },
        {
          title: '經銷據點',
          sub_title: 'Dealer',
          link: '/find_us',
        },
        {
          title: '聯絡我們',
          sub_title: 'Contact',
          link: '/contact',
        },
        {
          title: '產品列表',
          sub_title: 'Products',
          link: '/model',
        },
        {
          title: '最新消息',
          sub_title: 'News',
          link: '/news',
        },
        {
          title: '關於我們',
          sub_title: 'About',
          link: '/about',
        },
      ],
      main_menu_animation: null,
      resize_timer: null,
    };
  },
  methods: {
    Open() {
      this.main_menu_animation.open();
    },
    Close() {
      this.main_menu_animation.close();
    },
  },
  watch: {
    main_menu() {
      this.main_menu ? this.Open() : this.Close();
    },
  },
  computed: {
    main_menu() {
      return this.$store.state.main_menu;
    },
  },
  mounted() {
    this.main_menu_animation = new main_menu_animation(this.$refs.MainContent);
    // window.addEventListener('resize', () => {
    //   this.resize_timer != null ? clearTimeout(this.resize_timer) : '';
    //   this.resize_timer = setTimeout(() => {
    //     this.$store.commit('SetMenu', false);
    //   }, 1000);
    // });
  },
};
</script>
