<template>
  <router-link
    :to="link"
    @mouseenter.native="hover = true"
    @mouseleave.native="hover = false"
    class="relative flex items-center justify-center w-[120px] h-[120px] flex-shrink-0"
  >
    <div class="block">
      <span
        :class="
          hover
            ? ' text-light-red text-opacity-80'
            : ' text-light-red text-opacity-100'
        "
        class="z-[1] text-7xl transition-all duration-300 transform icon-arrow_right_up"
      ></span>
      <p
        :class="hover ? 'text-red' : 'text-gray text-opacity-80'"
        class="relative z-20 w-full font-bold text-center transition-all duration-300"
      >
        {{ text }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'MoreButton',
  props: {
    text: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
