import Vue from "vue";
import store from "@/store";
import router from "@/router";
var default_meta = {
  title: "官方網站｜牛王排氣管｜BullKing Exhaust",
  content:
    "Bull-king牛王競技部品成立於2014年，主項目為各式機車排氣管生產製造及各式排氣管代工生產，製程全部由牛王獨立工廠完成，排氣管品質穩定且優良，秉持產品創新開發、設計、製造。",
  image: "https://www.bullking-exhaust.com/img/share/index.jpg",
};

Vue.prototype.$ImageUrl = (item) => {
  return item == "" ? "" : process.env.VUE_APP_BASEURL + item;
};

Vue.prototype.$GetCloumn = (key) => {
  const column = store.state.common_column_data.filter(
    (column) => column.Title == key
  );
  return column.length <= 0 ? "" : column[0].Content;
};

Vue.prototype.$MoneyFormat = (price) => {
  if (typeof price != "number") {
    return price;
  } else {
    let val = (price / 1).toFixed(0).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

Vue.prototype.$GetMetaData = (title, content, image) => {
  title = title == "" ? default_meta.title : title;
  content = content == "" ? default_meta.content : content;
  content.length > 155 ? (content = content.slice(0, 155) + "...") : "";
  return {
    title: title + "｜牛王排氣管｜BullKing Exhaust",
    meta: [
      {
        property: "og:url",
        name: "og:url",
        content: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
        vmid: "og:url",
      },
      {
        property: "og:title",
        name: "og:title",
        content: title + "｜牛王排氣管｜BullKing Exhaust",
        vmid: "og:title",
      },
      {
        property: "og:description",
        name: "og:description",
        content: content,
        vmid: "og:description",
      },
      {
        property: "twitter:description",
        name: "twitter:description",
        content: content,
        vmid: "twitter:description",
      },
      {
        property: "description",
        name: "description",
        content: content,
        vmid: "description",
      },
      {
        property: "og:image",
        name: "og:image",
        content: image == "" ? default_meta.image : image,
        vmid: "og:image",
      },
      {
        property: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: title + "｜牛王排氣管｜BullKing Exhaust",
        vmid: "apple-mobile-web-app-title",
      },
      {
        property: "application-name",
        name: "application-name",
        content: title + "｜牛王排氣管｜BullKing Exhaust",
        vmid: "application-name",
      },
      {
        property: "og:site_name",
        name: "og:site_name",
        content: "官方網站｜牛王排氣管｜BullKing Exhaust",
        vmid: "og:site_name",
      },
      {
        property: "og:type",
        name: "og:type",
        content: "website",
        vmid: "og:type",
      },
      {
        property: "og:locale",
        name: "og:locale",
        content: "zh_tw",
        vmid: "og:locale",
      },
    ],
  };
};

// 頁面初始化完成，準備好渲染
Vue.prototype.$PageReady = (title, tiktok_title = "", product_id = "") => {
  CheckFontLoad();
  // 告知prerender.io 畫面已準備好可以渲染
  window.prerenderReady = true;
  // GTM事件，頁面瀏覽
  window.dataLayer.push({
    event: "page_view",
    page_title: title,
  });
  // TIKTOK
  if (tiktok_title != "") {
    let tiktok_data = {
      value: 0,
      currency: "TWD",
      description: tiktok_title,
    };
    product_id != "" ? (tiktok_data.content_id = product_id) : "";
    window.ttq.track("ViewContent", tiktok_data);
  }
};

// 讀取cdn套件
Vue.prototype.$LoadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

// 個位數前面補零
Vue.prototype.$AddZeroPad = (val) => {
  return parseInt(val) < 10 ? "0" + val : val;
};

// 初始化
Vue.prototype.$LoadDataMixin = (component) => {
  // 若網站資料已經讀取完成，則呼叫頁面初始化
  if (component.$store.getters.data_load_finish) {
    component.PageInit();
  }
  // 監聽網站資料讀取裝態，當狀態完成時呼叫頁面初始化
  component.$watch("$store.getters.data_load_finish", (newVal) => {
    if (newVal) {
      component.PageInit();
    }
  });
  // 監聽網站圖片讀取裝態，當狀態完成時呼叫動畫初始化
  component.$watch("$store.state.image_loaded", (newVal) => {
    if (newVal) {
      component.SetGsap();
    }
  });
};

Vue.prototype.$LoadAllFont = () => {
  LoadAllFont();
};

function LoadAllFont() {
  if (
    document.head.querySelectorAll(
      '[href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap"]'
    ).length <= 0
  ) {
    let font_1_link = document.createElement("link");
    font_1_link.href =
      "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap";
    font_1_link.rel = "stylesheet";
    font_1_link.type = "text/css";
    document.head.appendChild(font_1_link);

    let font_2_link = document.createElement("link");
    font_2_link.href =
      "https://api.fontshare.com/v2/css?f[]=panchang@700,600&display=swap";
    font_2_link.rel = "stylesheet";
    font_2_link.type = "text/css";
    document.head.appendChild(font_2_link);
  }
}

function LoadFont(text) {
  if (
    document.head.querySelectorAll(
      '[href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap"]'
    ).length <= 0
  ) {
    let css_link = document.createElement("link");
    css_link.href =
      "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap&text=" +
      encodeURIComponent(text);
    css_link.rel = "stylesheet";
    css_link.type = "text/css";
    document.head.appendChild(css_link);

    css_link = document.createElement("link");
    css_link.href =
      "https://api.fontshare.com/v2/css?f[]=panchang@700,600&display=swap&text=" +
      encodeURIComponent(text);
    css_link.rel = "stylesheet";
    css_link.type = "text/css";
    document.head.appendChild(css_link);
  }
}

function GetBodyText() {
  let str = document.body.innerText;
  let uniqueChars = "";
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) !== " " && uniqueChars.indexOf(str.charAt(i)) === -1) {
      uniqueChars += str[i];
    }
  }
  uniqueChars = uniqueChars.replace(/\s/g, "");
  str = [...new Set(uniqueChars)].join("");

  str = str.replace(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
    ""
  );
  return str;
}

function CheckFontLoad() {
  const links = document.head.querySelectorAll("link");
  let font_loaded = false;
  links.forEach((link) => {
    link.href.indexOf("css2?family=") != -1 ? (font_loaded = true) : "";
  });
  if (font_loaded) {
    LoadAllFont();
  } else {
    const body_text = GetBodyText();
    body_text != "" ? LoadFont(body_text) : "";
  }
}
