<template>
  <section ref="MainContent" class="relative z-10 w-full py-20 bg-red">
    <div
      class="relative z-10 flex justify-center w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10"
    >
      <div class="inline-block w-full md:w-auto">
        <h2 class="overflow-hidden">
          <span
            data-title
            class="block md:text-[100px] sm:text-[80px] text-[50px] font-panchang text-red"
          >
            Buy Now
          </span>
        </h2>
        <i data-title-line class="w-10 h-[1px] bg-white block mb-6"></i>
        <h3 class="mb-10 overflow-hidden sm:mb-10">
          <span
            data-title
            class="md:text-[40px] sm:text-[36px] text-2xl leading-10 text-white font-bold block"
            >訂製屬於您的排氣管</span
          >
        </h3>
        <div data-content>
          <PCMoreButton text="立即前往" link="/store" />
        </div>
      </div>
    </div>

    <div
      class="absolute top-0 left-0 z-0 w-full h-full bg-black opacity-30"
    ></div>
    <div
      class="absolute top-0 left-0 z-0 w-full h-full opacity-50 noise_bg"
    ></div>
  </section>
</template>

<script>
import PCMoreButton from '@/components/footer/MoreButton@lg.vue';
import { buy_action_animation } from '@/gsap/footer/buy_action';
export default {
  name: 'BuyActionFooter',
  components: {
    PCMoreButton,
  },
  data() {
    return {
      buy_action_animation: null,
    };
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded) {
        this.buy_action_animation = new buy_action_animation(
          this.$refs.MainContent
        );
      }
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
};
</script>
