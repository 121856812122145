import axios from 'axios';
import store from '@/store';

let baseURL = process.env.VUE_APP_BASE_API;
// let baseURL = "";
// 建立axios例項
const service = axios.create({
  baseURL: baseURL,
  timeout: 30000, // 請求超時時間
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
// axios.defaults.withCredentials = true; // 若跨域請求需要帶 cookie 身份識別
// axios.defaults.headers.post["Access-Control-Allow-Origin-Type"] = "*"; // 允許跨域
const err = (error) => {
  if (error.response) {
    let data = error.response.data;
    console.log(`message: ${data.msg}`);
  }
  return Promise.reject(error);
};
// request攔截器
service.interceptors.request.use(
  (config) => {
    // const token = this.$store.state.token;
    // if (this.$store.state.token) {
    //     config.headers["X-Token"] = token;
    // }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);
// respone攔截器
service.interceptors.response.use((response) => {
  // console.log("response.data", response.data);
  return response.data;
}, err);

let showDialog = (res) => {
  store.commit('SetDialog', res);
};

/*
 *  get請求
 *  url:請求地址
 *  params:引數
 * */
export function get(url, params = {}) {
  store.commit('SetLoading', 1);
  return new Promise((resolve, reject) => {
    // console.log("process.env.VUE_APP_BASE_API", process.env.VUE_APP_BASE_API);
    service({
      url: url,
      method: 'get',
      params: params,
    })
      .then((response) => {
        resolve(response);
        store.commit('SetLoading', -1);
      })
      .catch((error) => {
        reject(error);
        store.commit('SetLoading', -1);
      });
  });
}

/*
 *  post請求
 *  url:請求地址
 *  params:引數
 * */
export function zero_card_post(url, params = {}, api_key, merchant_id) {
  store.commit('SetLoading', 1);
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'post',
      data: params,
      headers: {
        '0Card-Merchant-Id': merchant_id,
        '0Card-API-Key': api_key,
      },
    })
      .then((response) => {
        resolve(response);
        store.commit('SetLoading', -1);
      })
      .catch((error) => {
        reject(error);
        store.commit('SetLoading', -1);
      });
  });
}

/*
 *  put請求
 *  url:請求地址
 *  params:引數
 * */
export function put(url, params = {}) {
  store.commit('SetLoading', 1);
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'put',
      data: params,
    })
      .then((response) => {
        resolve(response);
        response.code != 200 ? showDialog(response) : '';

        store.commit('SetLoading', -1);
      })
      .catch((error) => {
        reject(error);
        store.commit('SetLoading', -1);
      });
  });
}

/*
 *  patch請求
 *  url:請求地址
 *  params:引數
 * */
export function patch(url, params = {}) {
  store.commit('SetLoading', 1);
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'patch',
      data: params,
    })
      .then((response) => {
        resolve(response);
        response.code != 200 ? showDialog(response) : '';
        store.commit('SetLoading', -1);
      })
      .catch((error) => {
        reject(error);
        store.commit('SetLoading', -1);
      });
  });
}

/*
 *  delete請求
 *  url:請求地址
 *  params:引數
 * */
export function del(url) {
  store.commit('SetLoading', 1);
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'delete',
    })
      .then((response) => {
        response.code != 200 ? showDialog(response) : '';
        store.commit('SetLoading', -1);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        store.commit('SetLoading', -1);
      });
  });
}

/*
 *  post_image請求
 *  url:請求地址
 *  params:引數
 * */
export function post_form(url, params = {}) {
  store.commit('SetLoading', 1);
  return new Promise((resolve, reject) => {
    // console.log(service)
    // service.headers["Content-Type"] = "multipart/form-data;charset=UTF-8"
    service({
      url: url,
      method: 'post',
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
      },
    })
      .then((response) => {
        // console.log(response)
        resolve(response);
        store.commit('SetLoading', -1);
      })
      .catch((error) => {
        reject(error);
        store.commit('SetLoading', -1);
      });
  });
}

export default {
  get,
  zero_card_post,
  put,
  del,
  patch,
};
