import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.config({
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
});

export class news_list_animation {
  constructor(el) {
    this.el = el;

    // section el
    this.title = el.querySelectorAll('[data-title]');
    this.content = el.querySelectorAll('[data-content]');

    // gsap timeline
    this.section_timeline = null;

    // on created
    this.in();
  }

  in() {
    // scroll in
    this.section_timeline != null ? this.section_timeline.kill() : '';
    this.section_timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 60%',
        toggleActions: 'restart play play reverse',
      },
    });
    this.section_timeline
      .fromTo(
        this.title[0],
        {
          y: '105%',
        },
        {
          y: '0%',
        },
        'first'
      )
      .fromTo(
        this.title_line,
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          transformOrigin: 'left',
        },
        'first'
      )
      .fromTo(
        this.title[1],
        {
          x: '-105%',
        },
        {
          x: '0%',
        },
        'first'
      )
      .fromTo(
        this.content,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          y: '0%',
          delay: 0.4,
        },
        'first'
      );
  }

  reset(index) {
    ScrollTrigger.matchMedia({
      '(min-width:768px)': () => {
        gsap.set(this.cards, {
          xPercent: `${index * -100 + 25}`,
        });
      },
      '(max-width:767px) and (min-width:641px)': () => {
        gsap.set(this.cards, {
          xPercent: `${index * -100 + 25}`,
        });
      },
      '(max-width:640px)': () => {
        gsap.set(this.cards, {
          xPercent: `${index * -100 + 5}`,
        });
      },
    });
  }
}
