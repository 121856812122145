<template>
  <section
    ref="MainContent"
    class="relative w-full h-screen overflow-hidden opacity-0"
  >
    <div
      class="absolute top-[110px] md:right-14 md:left-auto sm:left-0 left-1/2 transform sm:-translate-x-0 -translate-x-1/2 px-8 py-3 flex items-center z-[12]"
    >
      <span
        data-number-box
        class="relative h-5 overflow-hidden text-white blcok"
      >
        <span
          v-for="(item, item_index) in carousel_data"
          :key="`now_index_${item_index}`"
          class="block text-sm font-medium font-panchang"
        >
          {{ (item_index + 1) | number }}
        </span>
      </span>
      <div
        data-progress-box
        class="md:w-[300px] sm:w-[200px] w-[60vw] relative h-[.5px] bg-opacity-50 bg-white mx-4"
      >
        <i
          data-progress
          class="absolute top-1/2 left-0 transform -translate-y-1/2 h-[1.5px] bg-white w-full scale-x-0"
        ></i>
      </div>
      <span
        data-number-box
        class="relative block h-5 overflow-hidden text-white"
      >
        <span
          v-for="(item, item_index) in carousel_data"
          :key="`now_index_${item_index}`"
          class="block text-sm font-medium font-panchang"
        >
          {{ (item_index + 1) | number }}
        </span>
      </span>
    </div>

    <ol
      data-title-group
      class="absolute sm:bottom-20 bottom-5 sm:left-14 left-7 right-7 sm:right-14 z-[11]"
    >
      <li
        data-title-box
        v-for="(item, item_index) in carousel_data"
        :key="`title_${item_index}`"
        class="absolute bottom-0 left-0 max-w-full"
      >
        <h2
          class="md:text-[80px] sm:text-[75px] text-xl flex items-center sm:mb-5 mb-2 overflow-hidden flex-wrap"
        >
          <span class="block w-full mr-5 overflow-hidden sm:mr-10 sm:w-auto">
            <span
              data-sub-title
              class="block font-semibold leading-none text-white text-red-700 text-opacity-40 font-panchang sm:text-white"
              >BULLKING</span
            >
          </span>
          <span
            class="block overflow-hidden md:text-[80px] sm:text-[75px] text-6xl"
          >
            <span
              data-title
              class="block py-1 overflow-hidden sm:bg-black sm:px-3 sm:py-3 bg-opacity-80"
            >
              <b class="block font-bold leading-none font-panchang text-gray">{{
                item.title
              }}</b>
            </span>
          </span>
        </h2>
        <div class="overflow-hidden">
          <p
            data-content
            class="md:text-3xl sm:text-[20px] text-base font-bold text-gray"
          >
            {{ item.content }}
          </p>
        </div>
      </li>
    </ol>

    <div
      data-scooter-group
      class="absolute top-0 bottom-0 left-0 right-0 z-10 w-full"
    >
      <div
        data-scooter-box
        v-for="(item, item_index) in carousel_data"
        :key="`carousel_${item_index}`"
        class="absolute z-10 flex sm:w-[80%] md:w-[70%] w-[90%] h-full max-w-screen-md xl:max-w-screen-lg transform -translate-x-1/2 -translate-y-1/2 lg:w-full top-1/2 left-1/2"
      >
        <div
          v-for="scooter_index in 5"
          :key="`scooter_${scooter_index}`"
          class="h-full w-[20%] overflow-hidden relative transform"
        >
          <!--background-image: url('${item.scooter}'); -->
          <div
            data-scooter
            :style="`left:${(scooter_index - 1) * -100}%`"
            class="xl:w-[1024px] md:w-[768px] sm:w-[78vw] block w-[86vw] h-full absolute transform bg-no-repeat lg:bg-auto bg-contain bg-center"
          >
            <img
              :src="item.scooter"
              :alt="item.title"
              class="block object-contain w-full h-full"
            />
          </div>
          <!-- - -->
        </div>
      </div>
    </div>

    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 w-full"
      data-background-group
    >
      <div
        v-for="(item, item_index) in carousel_data"
        :key="`carousel_${item_index}`"
        data-background-box
        class="absolute bottom-0 left-0 w-full h-full overflow-hidden"
      >
        <div
          data-text-box
          class="absolute sm:bottom-1/2 bottom-2/3 left-0 transform translate-y-1/2 flex md:h-[140px] w-auto h-[100px] z-10 opacity-50"
        >
          <img
            alt="BULLKING EXHAUST"
            src="/img/home_carousel/bullking_text.webp"
            width="1324"
            height="140"
            class="inline-block w-auto h-full mr-10 max-w-screen-2xl"
          />
          <img
            alt="BULLKING EXHAUST"
            src="/img/home_carousel/bullking_text.webp"
            width="1324"
            height="140"
            class="inline-block w-auto h-full mr-10 max-w-screen-2xl"
          />
          <img
            alt="BULLKING EXHAUST"
            src="/img/home_carousel/bullking_text.webp"
            width="1324"
            height="140"
            class="inline-block w-auto h-full mr-10 max-w-screen-2xl"
          />
          <img
            alt="BULLKING EXHAUST"
            src="/img/home_carousel/bullking_text.webp"
            width="1324"
            height="140"
            class="inline-block w-auto h-full mr-10 max-w-screen-2xl"
          />
        </div>

        <div
          data-background
          :class="item_index == 0 ? 'z-10' : 'z-0'"
          class="absolute bottom-0 left-0 z-0 hidden w-full h-screen bg-center bg-no-repeat bg-cover md:block"
        >
          <img
            v-lazy="item.background"
            class="block object-cover w-full h-full"
            :alt="item.title"
          />
        </div>
        <div
          data-background
          :class="item_index == 0 ? 'z-10' : 'z-0'"
          class="absolute top-0 left-0 right-0 block w-full h-screen bg-center bg-no-repeat bg-cover md:hidden"
        >
          <img
            v-lazy="item.background_sm"
            class="block object-cover w-full h-full"
            :alt="item.title"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { carousel_animation } from "@/gsap/home/carousel";
export default {
  name: "HomeCarousel",
  data() {
    return {
      carousel_animation: null,
      carousel_data: [
        {
          carousel_id: 6,
          title: "SVR",
          content: "經典節燒尾出口x最新合法砲管",
          scooter: "/img/home_carousel/DRG SVR實車圖.webp",
          background: "/img/home_carousel/DRG SVR背景圖.webp",
          background_sm: "/img/home_carousel/DRG SVR背景圖@sm.webp",
        },
        {
          carousel_id: 5,
          title: "SVJ",
          content: "擴散式CNC尾蓋x最新砲管樣式",
          scooter: "/img/home_carousel/DRG SVJ實車圖.webp",
          background: "/img/home_carousel/DRG SVJ背景圖.webp",
          background_sm: "/img/home_carousel/DRG SVJ背景圖@sm.webp",
        },
        {
          carousel_id: 1,
          title: "BK999",
          content: "巔峰極至的馬力輸出唯一選擇排氣管，馬力輸出不再受阻礙。",
          scooter: "/img/home_carousel/DRG BK999.webp",
          background: "/img/home_carousel/DRG BK999背景圖.webp",
          background_sm: "/img/home_carousel/DRG BK999背景圖@sm.webp",
        },
        {
          carousel_id: 2,
          title: "BX7",
          content: "用細節展現誠意，用價格實現高CP值的白鐵排氣管。",
          scooter: "/img/home_carousel/DRG BX-7實車圖.webp",
          background: "/img/home_carousel/DRG BX-7背景圖.webp",
          background_sm: "/img/home_carousel/DRG BX-7背景圖@sm.webp",
        },
        {
          carousel_id: 3,
          title: "BX7",
          content: "用細節展現誠意，用價格實現高CP值的白鐵排氣管。",
          scooter: "/img/home_carousel/RCS150 BX-7實車圖.webp",
          background: "/img/home_carousel/RCS150 BX-7背景圖.webp",
          background_sm: "/img/home_carousel/RCS150 BX-7背景圖@sm.webp",
        },
        {
          carousel_id: 4,
          title: "BX7",
          content: "用細節展現誠意，用價格實現高CP值的白鐵排氣管。",
          scooter: "/img/home_carousel/FORCE BX-7實車圖.webp",
          background: "/img/home_carousel/FORCE2 BX-7背景圖.webp",
          background_sm: "/img/home_carousel/FORCE2 BX-7背景圖@sm.webp",
        },
        // {
        //   carousel_id: 1,
        //   title: "BX7",
        //   content: "用細節展現誠意，用價格實現高CP值的白鐵排氣管。",
        //   scooter: "/img/home_carousel/DRG BX-7實車圖.webp",
        //   background: "/img/home_carousel/DRG BX-7背景圖.webp",
        //   background_sm: "/img/home_carousel/DRG BX-7背景圖@sm.webp",
        // },
        // {
        //   carousel_id: 2,
        //   title: "BX1",
        //   content: "入門改裝族首選排氣管，價格親民好入手。",
        //   scooter: "/img/home_carousel/勁戰BX-1實車圖.webp",
        //   background: "/img/home_carousel/勁戰BX-1背景圖.webp",
        //   background_sm: "/img/home_carousel/勁戰BX-1背景圖@sm.webp",
        // },
        // {
        //   carousel_id: 3,
        //   title: "BK999",
        //   content: "巔峰極至的馬力輸出唯一選擇排氣管，馬力輸出不再受阻礙。",
        //   scooter: "/img/home_carousel/DRG BK999實車圖.webp",
        //   background: "/img/home_carousel/DRG BK999背景圖.webp",
        //   background_sm: "/img/home_carousel/DRG BK999背景圖@sm.webp",
        // },
        // {
        //   carousel_id: 4,
        //   title: "BX7",
        //   content: "用細節展現誠意，用價格實現高CP值的白鐵排氣管。",
        //   scooter: "/img/home_carousel/FORCE20 BX-7實車圖.webp",
        //   background: "/img/home_carousel/FORCE20 BX-7背景圖.webp",
        //   background_sm: "/img/home_carousel/FORCE20 BX-7背景圖@sm.webp",
        // },
        // {
        //   carousel_id: 5,
        //   title: "BX7",
        //   content: "用細節展現誠意，用價格實現高CP值的白鐵排氣管。",
        //   scooter: "/img/home_carousel/VJR125 BX-7實車圖.webp",
        //   background: "/img/home_carousel/VJR125 BX-7背景圖.webp",
        //   background_sm: "/img/home_carousel/VJR125 BX-7背景圖@sm.webp",
        // },
      ],
    };
  },
  methods: {
    nextCarousel() {
      this.carousel_animation.changeCarousel(1);
    },
    SetGsap() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.carousel_animation = new carousel_animation(
            this.$refs.MainContent,
            this.carousel_data
          );
          this.carousel_animation.startCarousel();
        }, 1800);
      });
    },
  },
  created() {
    //  設定首頁輪播圖preload 增加效能
    if (window.innerWidth >= 768) {
      let pc_image_link = document.createElement("link");
      pc_image_link.as = "image";
      pc_image_link.rel = "preload";
      pc_image_link.href = encodeURI(
        process.env.VUE_APP_BASEURL + this.carousel_data[0].background

        // 'http://localhost:8080' + this.carousel_data[0].background
      );
      document.head.appendChild(pc_image_link);
    } else {
      let mb_image_link = document.createElement("link");
      mb_image_link.as = "image";
      mb_image_link.rel = "preload";
      mb_image_link.href =
        process.env.VUE_APP_BASEURL + this.carousel_data[0].background_sm;
      // 'http://localhost:8080' + this.carousel_data[0].background_sm;
      document.head.appendChild(mb_image_link);
    }
  },
  filters: {
    number(val) {
      return val >= 10 ? val : "0" + val;
    },
  },
};
</script>
