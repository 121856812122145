import { gsap } from "@/gsap/gsap_loader";
import { horizon_scroll } from "@/gsap/horizon_scroll";

gsap.config({
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
});

export class carousel_animation extends horizon_scroll {
  constructor(el, carousel_data) {
    super();
    this.el = el;
    this.carousel_data = carousel_data;
    this.scooter_group = el.querySelector("[data-scooter-group]");
    this.background_group = el.querySelector("[data-background-group]");
    this.title_group = el.querySelector("[data-title-group]");
    this.progress = el.querySelector("[data-progress]");
    this.progress_box = el.querySelector("[data-progress-box]");
    this.number_box = el.querySelectorAll("[data-number-box]");
    this.timeline = null;
    this.text_timeline = [];
    this.progress_timeline = null;
    this.timer = null;
    this.active_index = 0;
    this.reset();
  }

  reset() {
    gsap.set(this.el, { opacity: 1 });
    gsap.set(this.number_box[0].querySelectorAll("span"), { y: "105%" });
    gsap.set(this.number_box[1].querySelectorAll("span"), { y: "105%" });
    this.carousel_data.forEach((item, item_index) => {
      const carousel = this.getCarousel(item_index);

      gsap.set(carousel.scooter_box, {
        zIndex: 0,
      });
      gsap.set(carousel.title_box, {
        zIndex: 0,
      });
      gsap.set(carousel.background_box, {
        zIndex: 0,
        x: "-100%",
      });
      gsap.set(carousel.scooters, {
        x: "-100%",
      });
      // gsap.set(carousel.background, {
      //   scale: 1.3,
      // });

      gsap.set(carousel.sub_title, {
        y: "100%",
      });
      gsap.set(carousel.title, {
        y: "100%",
      });

      gsap.set(carousel.title_text, {
        y: "100%",
      });
      gsap.set(carousel.content, {
        y: "100%",
      });
    });
  }

  startCarousel() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();
    this.setTextCarousel();

    const next_carousel = this.getCarousel(this.active_index);

    this.timeline
      .set(next_carousel.scooter_box, { zIndex: 10 })
      .set(next_carousel.background_box, { zIndex: 10 })
      .set(next_carousel.title_box, { zIndex: 10 })
      .fromTo(
        next_carousel.background_box,
        {
          x: "-100%",
        },
        {
          x: "0%",
          duration: 1,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.scooters,
        {
          x: "-100%",
          opacity: 0,
        },
        {
          x: "0%",
          opacity: 1,
          stagger: -0.1,
          duration: 0.6,
          delay: 0.4,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.sub_title,
        {
          opacity: 1,
          y: "100%",
        },
        {
          opacity: 1,
          y: "0%",
          duration: 0.6,
          delay: 0.4,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.title,
        {
          opacity: 1,
          y: "105%",
        },
        {
          opacity: 1,
          y: "0%",
          duration: 0.6,
          delay: 0.5,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.title_text,
        {
          y: "105%",
        },
        {
          y: "0%",
          duration: 0.6,
          delay: 0.65,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.content,
        {
          opacity: 1,
          y: "105%",
        },
        {
          opacity: 1,
          y: "0%",
          duration: 0.6,
          delay: 0.6,
          onComplete: () => {
            this.changeTimeline(this.carousel_data.length - 1, 0);
            this.timer != null ? clearTimeout(this.timer) : "";
            this.timer = setTimeout(() => {
              this.changeCarousel(1);
            }, 5000);
          },
        },
        "carousel_in"
      );
  }

  changeCarousel(action) {
    const old_val = this.active_index;
    const next_index = this.getNextIndex(action);
    const now_carousel = this.getCarousel(this.active_index);
    const next_carousel = this.getCarousel(next_index);

    this.timer != null ? clearTimeout(this.timer) : "";
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();

    this.carousel_data.forEach((item, item_index) => {
      if (item_index != this.active_index && item_index != next_index) {
        const scooter =
          this.scooter_group.querySelectorAll("[data-scooter-box]")[item_index];
        const background = this.background_group.querySelectorAll(
          "[data-background-box]"
        )[item_index];
        gsap.set(scooter, { zIndex: 0 });
        gsap.set(background, { zIndex: 0 });
      }
    });

    this.timeline
      .set(now_carousel.scooter_box, { zIndex: 5 })
      .set(now_carousel.background_box, { zIndex: 5 })
      .set(next_carousel.scooter_box, { zIndex: 10 })
      .set(next_carousel.background_box, { zIndex: 10 })
      .fromTo(
        now_carousel.sub_title,
        {
          y: "0%",
        },
        {
          y: "-105%",
          duration: 0.4,
        },
        "carousel_in"
      )
      .fromTo(
        now_carousel.title,
        {
          y: "0%",
        },
        {
          y: "-105%",
          duration: 0.4,
          delay: 0.2,
        },
        "carousel_in"
      )
      .fromTo(
        now_carousel.content,
        {
          y: "0%",
        },
        {
          y: "-110%",
          duration: 0.4,
          delay: 0.2,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.background_box,
        {
          x: "-100%",
        },
        {
          x: "0%",
          duration: 1,
        },
        "carousel_in"
      )
      .fromTo(
        now_carousel.scooters,
        {
          x: "0%",
          opacity: 1,
        },
        {
          x: "100%",
          opacity: 0,
          stagger: -0.1,
          duration: 0.6,
          delay: 0.4,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.scooters,
        {
          x: "-100%",
          opacity: 0,
        },
        {
          x: "0%",
          opacity: 1,
          stagger: -0.1,
          duration: 0.6,
          delay: 0.4,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.sub_title,
        {
          opacity: 1,
          y: "100%",
        },
        {
          opacity: 1,
          y: "0%",
          duration: 0.6,
          delay: 0.4,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.title,
        {
          opacity: 1,
          y: "105%",
        },
        {
          opacity: 1,
          y: "0%",
          duration: 0.6,
          delay: 0.5,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.title_text,
        {
          y: "105%",
        },
        {
          y: "0%",
          duration: 0.6,
          delay: 0.65,
        },
        "carousel_in"
      )
      .fromTo(
        next_carousel.content,
        {
          opacity: 1,
          y: "105%",
        },
        {
          opacity: 1,
          y: "0%",
          duration: 0.6,
          delay: 0.6,
          onComplete: () => {
            this.changeTimeline(old_val, next_index);
            this.timer != null ? clearTimeout(this.timer) : "";
            this.timer = setTimeout(() => {
              this.changeCarousel(1);
            }, 5000);
          },
        },
        "carousel_in"
      );
    this.active_index = next_index;
  }

  setTextCarousel() {
    this.text_timelline = [];
    const bg_box = this.background_group.querySelectorAll(
      "[data-background-box]"
    );
    bg_box.forEach((box) => {
      const text_box = box.querySelector("[data-text-box]");
      const text = text_box.querySelectorAll("img");
      let tl = this.setScrollForward(text, 1);
      this.text_timeline.push(tl);
    });
  }

  changeTimeline() {
    const next_active =
      this.active_index == this.carousel_data.length - 1
        ? 0
        : this.active_index + 1;

    const left_number = this.number_box[0].querySelectorAll("span");
    const right_number = this.number_box[1].querySelectorAll("span");

    this.progress_timeline != null ? this.progress_timeline.kill() : "";
    this.progress_timeline = gsap.timeline();

    this.progress_timeline
      .to(
        left_number,
        {
          y: `${this.active_index * -100}%`,
        },
        "same"
      )
      .to(
        right_number,
        {
          y: `${next_active * -100}%`,
        },
        "same"
      )
      .fromTo(
        this.progress,
        {
          scaleX: 0,
          transformOrigin: "left",
        },
        {
          scaleX: 1,
          transformOrigin: "left",
          duration: 5,
          ease: "none",
        }
      );
  }

  getNextIndex(action) {
    let index = 0;
    if (action == -1) {
      index =
        this.active_index == 0
          ? this.carousel_data.length - 1
          : this.active_index - 1;
    } else {
      index =
        this.active_index == this.carousel_data.length - 1
          ? 0
          : this.active_index + 1;
    }
    return index;
  }

  getCarousel(index) {
    const scooter_box =
      this.scooter_group.querySelectorAll("[data-scooter-box]")[index];
    const background_box = this.background_group.querySelectorAll(
      "[data-background-box]"
    )[index];
    const title_box =
      this.title_group.querySelectorAll("[data-title-box]")[index];
    return {
      scooter_box: scooter_box,
      scooters: scooter_box.querySelectorAll("[data-scooter]"),
      background_box: background_box,
      background:
        this.background_group.querySelectorAll("[data-background]")[index],
      text_box: background_box.querySelector("[data-text-box]"),
      title_box: title_box,
      sub_title: title_box.querySelector("[data-sub-title]"),
      title: title_box.querySelector("[data-title]"),
      title_text: title_box.querySelector("[data-title] b"),
      content: title_box.querySelector("[data-content]"),
    };
  }
}
