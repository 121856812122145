import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/model',
    name: '商品列表',
    component: () =>
      import(/* webpackChunkName: "商品列表" */ '../views/ProductListView.vue'),
  },
  {
    path: '/model/:id',
    name: '商品介紹',
    component: () =>
      import(/* webpackChunkName: "商品介紹" */ '../views/ProductPageView.vue'),
  },
  {
    path: '/news',
    name: '最新消息列表',
    component: () =>
      import(
        /* webpackChunkName: "最新消息列表" */ '../views/NewsListView.vue'
      ),
  },
  {
    path: '/news/:id',
    name: '最新消息',
    component: () =>
      import(/* webpackChunkName: "最新消息" */ '../views/NewsPageView.vue'),
  },
  {
    path: '/about',
    name: '關於我們',
    component: () =>
      import(/* webpackChunkName: "關於我們" */ '../views/AboutView.vue'),
  },
  {
    path: '/privacy_policy',
    name: '隱私權政策',
    component: () =>
      import(
        /* webpackChunkName: "隱私權政策" */ '../views/PrivacyPolicyView.vue'
      ),
  },
  {
    path: '/ship_doc',
    name: '購買需知',
    component: () =>
      import(
        /* webpackChunkName: "購買需知" */ '../views/PurchaseNoticeView.vue'
      ),
  },
  {
    path: '/contact',
    name: '聯絡我們',
    component: () =>
      import(/* webpackChunkName: "聯絡我們" */ '../views/ContactView.vue'),
  },
  {
    path: '/error_page',
    name: '錯誤頁面',
    component: () =>
      import(/* webpackChunkName: "錯誤頁面" */ '../views/NotFoundView.vue'),
  },
  {
    path: '/find_us',
    name: '經銷列表',
    component: () =>
      import(/* webpackChunkName: "經銷列表" */ '../views/DealerListView.vue'),
  },
  {
    path: '/find_us/:id',
    name: '經銷地圖',
    component: () =>
      import(/* webpackChunkName: "經銷地圖" */ '../views/DealerView.vue'),
  },
  {
    path: '/store',
    name: '訂製排氣管',
    component: () =>
      import(/* webpackChunkName: "訂製排氣管" */ '../views/CustomizeView.vue'),
  },
  {
    path: '/checkout',
    name: '結帳',
    component: () =>
      import(/* webpackChunkName: "結帳" */ '../views/CheckoutView.vue'),
  },
  {
    path: '/order_create/:id/:type',
    name: '訂單完成',
    component: () =>
      import(
        /* webpackChunkName: "order_create" */ '../views/TradeFinishView.vue'
      ),
  },
  {
    path: '/findus',
    redirect: '/find_us',
  },
  {
    path: '/index.html',
    redirect: '/',
  },
  {
    path: '/index.php',
    redirect: '/',
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top

    document.querySelector('#app').scrollTo({ top: 0 });
    return {
      y: 0,
      x: 0,
    };
  },
});

export default router;
