<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl mx-auto"
  >
    <div class="relative z-10 w-full px-5 pb-8 pt-14 sm:px-10 sm:pt-24">
      <header class="relative z-10">
        <h3 class="overflow-hidden">
          <span
            data-title
            class="md:text-[100px] sm:text-[80px] text-[50px] font-panchang font-semibold text-white text-opacity-20 block"
            >Products</span
          >
        </h3>
        <i data-title-line class="w-10 h-[1px] bg-red block sm:mb-4 mb-2"></i>
        <h2 class="overflow-hidden">
          <span class="block font-bold text-white sm:text-2xl" data-title
            >排氣管一覽</span
          >
        </h2>
      </header>

      <div
        class="flex items-stretch justify-start w-full overflow-hidden whitespace-nowrap"
      >
        <div
          data-product-card
          v-for="(item, item_index) in product_data"
          :key="`product_card_${item_index}`"
          class="relative z-10 flex items-center justify-center flex-shrink-0 w-full py-5 overflow-hidden whitespace-normal"
        >
          <div class="relative z-10 flex items-center justify-center">
            <div data-product-image class="relative z-10 w-full">
              <img
                :alt="item.title + ' 牛王' + item.sub_title"
                :src="item.image.split('.webp')[0] + '@sm.webp'"
                width="460"
                height="274"
                class="block w-full"
              />
            </div>
            <div
              data-background-image
              class="absolute z-0 w-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 aspect-video"
            >
              <img
                :alt="item.title + ' 牛王' + item.sub_title"
                :src="item.background_image"
                width="460"
                height="259"
                class="relative z-10 object-cover w-full h-full opacity-60 contrast-125 brightness-50"
              />
              <div
                data-background-border
                class="absolute top-0 bottom-0 left-0 right-0 transform -translate-x-2 translate-y-1 border-2 border-black sm:-translate-x-4 sm:translate-y-4 opacity-20"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <ol data-button-list class="relative z-10 flex items-center pl-1 sm:pl-0">
        <li
          v-for="(item, item_index) in product_data"
          :key="`product_button_${item_index}`"
          class="mr-5"
        >
          <div class="relative">
            <button
              @click="ChangeCard(item_index)"
              :class="
                active_card == item_index
                  ? 'bg-red border-black'
                  : 'bg-transparent border-gray'
              "
              class="relative transition-all duration-300 border"
            >
              <img
                :alt="item.title + item.sub_title"
                :src="item.button_image"
                width="48"
                height="48"
                class="relative z-10 w-12 h-12 sm:w-14 sm:h-14"
              />
            </button>
            <div
              class="absolute bottom-0 left-0 w-full h-1 transform -translate-x-1 z-[1]"
            >
              <i
                :class="
                  active_card == item_index
                    ? 'border-black bg-black'
                    : 'border-gray bg-transparent'
                "
                class="block w-full h-full transition-all duration-300 border-b border-l border-r"
              ></i>
            </div>
            <div
              class="absolute top-0 left-0 w-1 h-full pb-1 transform -translate-x-[100%] translate-y-1"
            >
              <i
                :class="
                  active_card == item_index
                    ? 'border-black bg-black'
                    : 'border-gray bg-transparent'
                "
                class="block w-full h-full transition-all duration-300 border-t border-l"
              ></i>
            </div>
          </div>
        </li>
      </ol>

      <div
        class="absolute top-0 bottom-0 left-0 flex items-start pb-32 overflow-hidden sm:pb-0 lg:justify-end"
      >
        <img
          alt="牛王排氣管商品一覽"
          data-bg-text
          src="/img/home_products/background_text.svg"
          width="48"
          height="436"
          class="block h-full opacity-10"
        />
      </div>
      <div
        alt="background line"
        data-bg-line
        class="absolute top-0 right-0 z-0 bg-center bg-cover bg-no-repeat w-full h-full md:w-2/3 brightness-[.4]"
        v-lazy:background-image="'/img/home_products/bg_line.svg'"
      ></div>
    </div>

    <div
      class="flex items-stretch justify-start w-full overflow-hidden whitespace-nowrap pb-14"
    >
      <article
        data-content-box
        v-for="(item, item_index) in product_data"
        :key="`content_card_${item_index}`"
        class="flex-shrink-0 w-full px-5 pb-8 overflow-hidden whitespace-normal"
      >
        <div
          data-content
          class="w-full sm:px-10 sm:pb-24 sm:flex sm:items-end sm:justify-between"
        >
          <div>
            <div class="flex items-end mb-4">
              <span
                class="sm:px-4 px-2 py-[2px] sm:text-base text-sm mr-5 font-bold text-white bg-red flex-shrink-0 block"
              >
                {{ item.tag }}
              </span>
              <span
                class="block font-medium leading-none sm:text-2xl sm:font-normal font-panchang text-gray opacity-20"
              >
                BULLKING
              </span>
            </div>
            <div>
              <h2
                class="font-black lg:text-[120px] sm:text-[80px] text-5xl sm:mb-5 mb-1 font-panchang text-gray leading-none"
                v-html="item.title"
              ></h2>
              <h3
                class="mb-4 text-xl font-bold sm:mb-8 sm:text-3xl lg:text-4xl text-gray"
              >
                {{ item.sub_title }}
              </h3>
              <p
                class="text-sm font-bold mb-14 lg:text-base text-gray sm:mb-0"
                v-html="item.intro"
              ></p>
            </div>
          </div>
          <MBMoreButton
            text="立即購買"
            :link="item.link"
            class="hidden sm:flex"
          />
          <SMMoreButton
            text="立即購買"
            :link="item.link"
            class="flex w-full sm:hidden"
          />
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import { products_animation } from '@/gsap/home/mb_products';
import MBMoreButton from '@/components/home/products/MoreButton@sm.vue';
import SMMoreButton from '@/components/home/products/MoreButton@lg.vue';
import product_data from '@/assets/products.json';
export default {
  name: 'ProductSection',
  components: {
    MBMoreButton,
    SMMoreButton,
  },
  data() {
    return {
      product_data: product_data,
      products_animation: null,
      active_card: 0,
    };
  },
  methods: {
    ChangeCard(index) {
      if (index != this.active_card) {
        this.active_card = index;
        this.products_animation.changeCard(index);
      }
    },
    SetGsap() {
      this.products_animation = new products_animation(this.$refs.MainContent);
    },
  },
};
</script>
