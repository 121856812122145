<template>
  <router-link
    :to="link"
    @mouseenter.native="hover = true"
    @mouseleave.native="hover = false"
    class="relative flex items-end justify-start pt-5 pb-4 pl-5 pr-24 sm:pb-5 sm:pt-8 sm:pr-36"
  >
    <i class="flex-1 w-full h-[1px] bg-white block sm:mr-5 mr-2"></i>
    <p
      :class="hover ? 'text-white scale-110' : 'text-white '"
      class="relative z-20 font-bold leading-none transition-all duration-300 transform sm:text-xl"
    >
      {{ text }}
    </p>
    <span
      :class="
        hover
          ? 'translate-x-2 -translate-y-12 text-light-red text-opacity-80'
          : ' text-white text-opacity-100'
      "
      class="absolute z-[1] right-0 top-0 md:text-9xl text-8xl -translate-y-10 transition-all duration-300 transform icon-arrow_right_up"
    ></span>

    <div class="absolute top-0 left-0 z-0 w-full h-full">
      <div :class="hover ? 'opacity-0' : 'opacity-100'">
        <div
          class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat button_line_bg default"
        ></div>
      </div>
      <div :class="hover ? 'opacity-100' : 'opacity-0'">
        <div
          class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat bg_line_animate button_line_bg dark"
        ></div>
      </div>
    </div>
  </router-link>
</template>

<style scoped>
.bg_line_animate {
  animation: line_animation 1s infinite linear;
}

@keyframes line_animation {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 48px;
  }
}
</style>

<script>
export default {
  name: "MoreButton",
  props: {
    text: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
