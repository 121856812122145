<template>
  <section ref="MainContent" class="relative z-10 w-full pb-40 overflow-hidden">
    <div
      data-text-box
      class="flex md:h-[110px] py-7 h-[100px] relative z-10 mb-20 w-full"
    >
      <div
        class="flex-shrink-0 h-full px-5"
        v-for="text in 10"
        :key="`text_carousel_top_${text}`"
      >
        <img
          src="/img/contact_text.webp"
          alt="牛王排氣管聯絡我們"
          width="641"
          height="54"
          class="inline-block w-auto h-full max-w-screen-2xl"
        />
      </div>
    </div>

    <div
      class="relative z-10 w-full max-w-screen-xl px-5 mx-auto sm:px-10 xl:px-0"
    >
      <div class="flex flex-wrap items-start -mx-4">
        <div
          data-card
          @mouseenter="contact_hover = true"
          @mouseleave="contact_hover = false"
          class="relative w-full px-4 mb-10 md:w-1/2 md:mb-0"
        >
          <router-link
            to="/contact"
            :class="contact_hover ? 'translate-x-2 -translate-y-2' : ''"
            class="relative z-10 flex items-center justify-center w-full transition-all duration-500 transform"
          >
            <div
              class="absolute z-20 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            >
              <h3
                :class="contact_hover ? 'text-opacity-100' : 'text-opacity-30'"
                class="text-white font-panchang sm:text-[60px] text-[35px] transition-all duration-500"
              >
                Contact
              </h3>
              <p
                :class="contact_hover ? 'w-full' : 'sm:w-40 w-full'"
                class="inline-block px-8 py-2 text-sm font-bold text-white transition-all duration-500 border border-white sm:text-base"
              >
                聯絡我們
              </p>
            </div>
            <div
              :class="contact_hover ? 'opacity-40' : 'opacity-70'"
              class="absolute top-0 left-0 w-full transition-all duration-300 h-full z-[11] bg-black"
            ></div>
            <div
              :class="
                contact_hover
                  ? 'bg-red bg-opacity-100'
                  : 'bg-black bg-opacity-60'
              "
              class="absolute top-0 left-0 z-10 w-full h-full transition-all duration-300 bg-opacity-60 mix-blend-overlay"
            ></div>
            <img
              src="/img/contact/contact_1_1.webp"
              alt="牛王排氣管聯絡我們"
              width="624"
              height="421"
              class="relative z-0 block w-full grayscale"
            />
          </router-link>

          <div :class="contact_hover ? 'opacity-0' : 'opacity-100'">
            <div
              class="absolute top-0 z-0 h-full transition-all duration-500 transform -translate-x-2 translate-y-2 bg-repeat border sm:-translate-x-4 sm:translate-y-4 border-opacity-30 sm:left-5 left-4 right-5 border-gray button_line_bg default"
            ></div>
          </div>

          <div :class="contact_hover ? 'opacity-100' : 'opacity-0'">
            <div
              class="absolute top-0 z-0 h-full transition-all duration-500 transform -translate-x-2 translate-y-2 bg-repeat border sm:-translate-x-4 sm:translate-y-4 sm:left-5 left-4 right-5 border-red button_line_bg dark"
            ></div>
          </div>
        </div>
        <div
          data-card
          @mouseenter="dealer_hover = true"
          @mouseleave="dealer_hover = false"
          class="relative w-full px-4 md:w-1/2"
        >
          <router-link
            to="/find_us"
            :class="dealer_hover ? 'translate-x-2 -translate-y-2' : ''"
            class="relative z-10 flex items-center justify-center w-full transition-all duration-500 transform"
          >
            <div
              class="absolute z-20 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            >
              <h3
                :class="dealer_hover ? 'text-opacity-100' : 'text-opacity-30'"
                class="text-white font-panchang sm:text-[60px] text-[35px] transition-all duration-500"
              >
                Dealer
              </h3>
              <p
                :class="dealer_hover ? 'w-full' : 'sm:w-40 w-full'"
                class="inline-block px-8 py-2 text-sm font-bold text-white transition-all duration-500 border border-white sm:text-base"
              >
                尋找經銷商
              </p>
            </div>
            <div
              :class="dealer_hover ? 'opacity-40' : 'opacity-70'"
              class="absolute top-0 left-0 w-full transition-all duration-300 h-full z-[11] bg-black"
            ></div>
            <div
              :class="
                dealer_hover
                  ? 'bg-red bg-opacity-100'
                  : 'bg-black bg-opacity-60'
              "
              class="absolute top-0 left-0 z-10 w-full h-full transition-all duration-300 mix-blend-overlay"
            ></div>
            <img
              src="/img/contact/contact_2_1.webp"
              alt="牛王排氣管經銷商"
              width="624"
              height="421"
              class="relative z-0 block w-full grayscale"
            />
          </router-link>

          <div :class="dealer_hover ? 'opacity-0' : 'opacity-100'">
            <div
              class="absolute top-0 z-0 h-full transition-all duration-500 transform -translate-x-2 translate-y-2 bg-repeat border sm:-translate-x-4 sm:translate-y-4 border-opacity-30 sm:left-5 left-4 right-5 border-gray button_line_bg default"
            ></div>
          </div>
          <div :class="dealer_hover ? 'opacity-100' : 'opacity-0'">
            <div
              class="absolute top-0 z-0 h-full transition-all duration-500 transform -translate-x-2 translate-y-2 bg-repeat border sm:-translate-x-4 sm:translate-y-4 sm:left-5 left-4 right-5 border-red button_line_bg dark"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute top-0 left-0 z-0 w-full h-full">
      <div
        class="absolute top-0 left-0 w-full h-full opacity-30 z-[12] noise_bg"
      ></div>
      <div
        class="absolute top-0 left-0 z-[11] opacity-70 w-full h-full bg-light-gray mix-blend-exclusion"
      ></div>
      <img
        data-background-image
        src="/img/contact/區塊背景圖.webp"
        alt="牛王排氣管聯絡我們"
        width="1482"
        height="2210"
        class="object-cover w-full h-[150%] block relative z-10"
      />
    </div>
  </section>
</template>

<script>
import { contact_animation } from "@/gsap/footer/contact";
export default {
  name: "ContactFooter",
  data() {
    return {
      contact_hover: false,
      dealer_hover: false,
      contact_animation: null,
    };
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded) {
        this.contact_animation = new contact_animation(this.$refs.MainContent);
      }
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
};
</script>
