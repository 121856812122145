<template>
  <button
    @click="$emit('click')"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    class="relative flex items-center justify-center flex-shrink-0 sm:w-20 sm:h-20 w-14 h-14"
  >
    <div class="relative z-10 block">
      <span
        :class="
          hover
            ? ' text-light-red text-opacity-80'
            : ' text-white text-opacity-100'
        "
        class="z-[1] sm:text-7xl text-6xl mx-auto transition-all duration-300 transform icon-close"
      ></span>
    </div>

    <div class="absolute top-0 left-0 z-0 w-full h-full">
      <div :class="hover ? 'opacity-0' : 'opacity-100'">
        <div
          class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat button_line_bg default"
        ></div>
      </div>
      <div :class="hover ? 'opacity-100' : 'opacity-0'">
        <div
          class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat bg_line_animate button_line_bg dark"
        ></div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "MoreButton",
  data() {
    return {
      hover: false,
    };
  },
};
</script>
