var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"MainContent",staticClass:"relative z-10 w-full max-w-screen-xl mx-auto"},[_c('div',{staticClass:"relative z-10 w-full px-5 pb-8 pt-14 sm:px-10 sm:pt-24"},[_vm._m(0),_c('div',{staticClass:"flex items-stretch justify-start w-full overflow-hidden whitespace-nowrap"},_vm._l((_vm.product_data),function(item,item_index){return _c('div',{key:`product_card_${item_index}`,staticClass:"relative z-10 flex items-center justify-center flex-shrink-0 w-full py-5 overflow-hidden whitespace-normal",attrs:{"data-product-card":""}},[_c('div',{staticClass:"relative z-10 flex items-center justify-center"},[_c('div',{staticClass:"relative z-10 w-full",attrs:{"data-product-image":""}},[_c('img',{staticClass:"block w-full",attrs:{"alt":item.title + ' 牛王' + item.sub_title,"src":item.image.split('.webp')[0] + '@sm.webp',"width":"460","height":"274"}})]),_c('div',{staticClass:"absolute z-0 w-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 aspect-video",attrs:{"data-background-image":""}},[_c('img',{staticClass:"relative z-10 object-cover w-full h-full opacity-60 contrast-125 brightness-50",attrs:{"alt":item.title + ' 牛王' + item.sub_title,"src":item.background_image,"width":"460","height":"259"}}),_c('div',{staticClass:"absolute top-0 bottom-0 left-0 right-0 transform -translate-x-2 translate-y-1 border-2 border-black sm:-translate-x-4 sm:translate-y-4 opacity-20",attrs:{"data-background-border":""}})])])])}),0),_c('ol',{staticClass:"relative z-10 flex items-center pl-1 sm:pl-0",attrs:{"data-button-list":""}},_vm._l((_vm.product_data),function(item,item_index){return _c('li',{key:`product_button_${item_index}`,staticClass:"mr-5"},[_c('div',{staticClass:"relative"},[_c('button',{staticClass:"relative transition-all duration-300 border",class:_vm.active_card == item_index
                ? 'bg-red border-black'
                : 'bg-transparent border-gray',on:{"click":function($event){return _vm.ChangeCard(item_index)}}},[_c('img',{staticClass:"relative z-10 w-12 h-12 sm:w-14 sm:h-14",attrs:{"alt":item.title + item.sub_title,"src":item.button_image,"width":"48","height":"48"}})]),_c('div',{staticClass:"absolute bottom-0 left-0 w-full h-1 transform -translate-x-1 z-[1]"},[_c('i',{staticClass:"block w-full h-full transition-all duration-300 border-b border-l border-r",class:_vm.active_card == item_index
                  ? 'border-black bg-black'
                  : 'border-gray bg-transparent'})]),_c('div',{staticClass:"absolute top-0 left-0 w-1 h-full pb-1 transform -translate-x-[100%] translate-y-1"},[_c('i',{staticClass:"block w-full h-full transition-all duration-300 border-t border-l",class:_vm.active_card == item_index
                  ? 'border-black bg-black'
                  : 'border-gray bg-transparent'})])])])}),0),_vm._m(1),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:('/img/home_products/bg_line.svg'),expression:"'/img/home_products/bg_line.svg'",arg:"background-image"}],staticClass:"absolute top-0 right-0 z-0 bg-center bg-cover bg-no-repeat w-full h-full md:w-2/3 brightness-[.4]",attrs:{"alt":"background line","data-bg-line":""}})]),_c('div',{staticClass:"flex items-stretch justify-start w-full overflow-hidden whitespace-nowrap pb-14"},_vm._l((_vm.product_data),function(item,item_index){return _c('article',{key:`content_card_${item_index}`,staticClass:"flex-shrink-0 w-full px-5 pb-8 overflow-hidden whitespace-normal",attrs:{"data-content-box":""}},[_c('div',{staticClass:"w-full sm:px-10 sm:pb-24 sm:flex sm:items-end sm:justify-between",attrs:{"data-content":""}},[_c('div',[_c('div',{staticClass:"flex items-end mb-4"},[_c('span',{staticClass:"sm:px-4 px-2 py-[2px] sm:text-base text-sm mr-5 font-bold text-white bg-red flex-shrink-0 block"},[_vm._v(" "+_vm._s(item.tag)+" ")]),_c('span',{staticClass:"block font-medium leading-none sm:text-2xl sm:font-normal font-panchang text-gray opacity-20"},[_vm._v(" BULLKING ")])]),_c('div',[_c('h2',{staticClass:"font-black lg:text-[120px] sm:text-[80px] text-5xl sm:mb-5 mb-1 font-panchang text-gray leading-none",domProps:{"innerHTML":_vm._s(item.title)}}),_c('h3',{staticClass:"mb-4 text-xl font-bold sm:mb-8 sm:text-3xl lg:text-4xl text-gray"},[_vm._v(" "+_vm._s(item.sub_title)+" ")]),_c('p',{staticClass:"text-sm font-bold mb-14 lg:text-base text-gray sm:mb-0",domProps:{"innerHTML":_vm._s(item.intro)}})])]),_c('MBMoreButton',{staticClass:"hidden sm:flex",attrs:{"text":"立即購買","link":item.link}}),_c('SMMoreButton',{staticClass:"flex w-full sm:hidden",attrs:{"text":"立即購買","link":item.link}})],1)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"relative z-10"},[_c('h3',{staticClass:"overflow-hidden"},[_c('span',{staticClass:"md:text-[100px] sm:text-[80px] text-[50px] font-panchang font-semibold text-white text-opacity-20 block",attrs:{"data-title":""}},[_vm._v("Products")])]),_c('i',{staticClass:"w-10 h-[1px] bg-red block sm:mb-4 mb-2",attrs:{"data-title-line":""}}),_c('h2',{staticClass:"overflow-hidden"},[_c('span',{staticClass:"block font-bold text-white sm:text-2xl",attrs:{"data-title":""}},[_vm._v("排氣管一覽")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute top-0 bottom-0 left-0 flex items-start pb-32 overflow-hidden sm:pb-0 lg:justify-end"},[_c('img',{staticClass:"block h-full opacity-10",attrs:{"alt":"牛王排氣管商品一覽","data-bg-text":"","src":"/img/home_products/background_text.svg","width":"48","height":"436"}})])
}]

export { render, staticRenderFns }