import gsap from "gsap";
import imagesloaded from "imagesloaded";
import store from "@/store/index";
import router from "@/router/index";

export function LoadImage() {
  // 計數器
  let count = 2;
  //鎖住body
  document.body.style.overflow = "hidden";
  store.commit("SetLoading", 1);
  store.commit("SetImageLoaded", false);
  //初始化
  const images = gsap.utils.toArray("img");

  //完成事件
  const showDemo = () => {
    //設定讀取完成
    console.log("all images have been loaded!");
    count -= 1;
    if (router.history.current.name == "經銷地圖") {
      count -= 1;
    }
    loadFinish();
  };

  //完成事件
  const bgLoaded = () => {
    //設定讀取完成
    console.log("all bg images have been loaded!");
    count -= 1;

    loadFinish();
  };

  const loadFinish = () => {
    if (count == 0) {
      //復原body
      document.body.style.overflow = "auto";
      window.scrollTo(0, 0);

      resetScreenHeight();

      window.addEventListener("resize", () => {
        resetScreenHeight();
      });

      //關閉讀取畫面
      store.commit("SetImageLoaded", true);
      store.commit("SetLoading", -1);
    }
  };

  const resetScreenHeight = () => {
    const h_full_el = document.querySelectorAll(".h-screen");
    h_full_el.forEach((item) => {
      item.style.height = `${window.innerHeight}px`;
    });
    window.ScrollTrigger.refresh();
  };

  if (router.history.current.name != "經銷地圖") {
    imagesloaded("div", { background: true }).on("always", bgLoaded);
  }
  imagesloaded(images).on("always", showDemo);
}
