import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
//ScrollTrigger.normalizeScroll(true);

gsap.config({
  // autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  // units: {
  //   left: '%',
  //   top: '%',
  //   rotation: 'rad',
  // },
});

export class news_list_animation {
  constructor(el, setActive) {
    this.el = el;

    // section el
    this.title_line = el.querySelector('[data-title-line]');
    this.title = el.querySelectorAll('[data-title]');
    this.content = el.querySelectorAll('[data-content]');

    // carousel el
    this.cards = el.querySelectorAll('[data-carousel-card]');

    // timeline progress el
    this.progress = el.querySelector('[data-progress]');
    this.progress_box = el.querySelector('[data-progress-box]');
    this.number_box = el.querySelectorAll('[data-number-box]');

    // gsap timeline
    this.section_timeline = null;
    this.carousel_timeline = null;
    this.progress_timeline = null;

    // default value and method
    this.active_card = 0;
    this.setActive = setActive;
    this.resize_timer = null;
    this.carousel_timer = null;

    // on created
    this.in();
    this.setActive(0);
    this.reset(this.cards.length / 3);
  }

  in() {
    // scroll in
    this.section_timeline != null ? this.section_timeline.kill() : '';
    this.section_timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 60%',
        toggleActions: 'restart play play reverse',
      },
    });
    this.section_timeline
      .fromTo(
        this.title[0],
        {
          y: '105%',
        },
        {
          y: '0%',
        },
        'first'
      )
      .fromTo(
        this.title_line,
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          transformOrigin: 'left',
        },
        'first'
      )
      .fromTo(
        this.title[1],
        {
          x: '-105%',
        },
        {
          x: '0%',
        },
        'first'
      )
      .fromTo(
        this.content,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          y: '0%',
          delay: 0.4,
          onComplete: () => {
            this.setTimelineTimer();
          },
        },
        'first'
      );
  }

  reset(index) {
    ScrollTrigger.matchMedia({
      '(min-width:768px)': () => {
        gsap.set(this.cards, {
          xPercent: `${index * -100 + 25}`,
        });
      },
      '(max-width:767px) and (min-width:641px)': () => {
        gsap.set(this.cards, {
          xPercent: `${index * -100 + 25}`,
        });
      },
      '(max-width:640px)': () => {
        gsap.set(this.cards, {
          xPercent: `${index * -100 + 5}`,
        });
      },
    });
  }

  next() {
    const card_count = this.cards.length;
    if (this.active_card == card_count / 3 - 1) {
      this.reset(card_count / 3 - 1);
      this.active_card = 0;
    } else {
      this.active_card += 1;
    }
    this.setActive(this.active_card);
    this.move();
  }

  prev() {
    const card_count = this.cards.length;
    if (this.active_card == 0) {
      this.reset((card_count / 3) * 2);
      this.active_card = card_count / 3 - 1;
      // this.reset();
    } else {
      this.active_card -= 1;
    }
    this.setActive(this.active_card);
    this.move();
  }

  move() {
    this.setTimelineTimer();
    const card_count = this.cards.length;
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.carousel_timeline = gsap.timeline({
      onStart: () => {},
    });
    ScrollTrigger.matchMedia({
      '(min-width:768px)': () => {
        this.carousel_timeline.to(this.cards, {
          xPercent: `${(card_count / 3 + this.active_card) * -100 + 25}`,
          ease: 'none',
        });
      },
      '(max-width:767px) and (min-width:641px)': () => {
        this.carousel_timeline.to(this.cards, {
          xPercent: `${(card_count / 3 + this.active_card) * -100 + 25}`,
          ease: 'none',
        });
      },
      '(max-width:640px)': () => {
        this.carousel_timeline.to(this.cards, {
          xPercent: `${(card_count / 3 + this.active_card) * -100 + 5}`,
          ease: 'none',
        });
      },
    });
  }

  setTimelineTimer() {
    this.carousel_timer != null ? clearTimeout(this.carousel_timer) : '';

    // 設定 active_card,active_card + 1 progress timline
    const next_number =
      this.active_card + 1 == this.cards.length / 3 ? 0 : this.active_card + 1;
    this.changeTimeline(this.active_card, next_number);
    // 設定五秒計時器，五秒後執行 active_card + 1 ,active_card + 2 progress timeline && next()
    // 繼續設定五秒計時器重複執行
    this.carousel_timer = setTimeout(() => {
      const left_number = next_number;
      const right_number =
        left_number + 1 == this.cards.length / 3 ? 0 : left_number + 1;
      this.changeTimeline(left_number, right_number);
      this.next();
    }, 5000);
  }

  changeTimeline() {
    const next_active =
      this.active_card == this.cards.length / 3 - 1 ? 0 : this.active_card + 1;

    const left_number = this.number_box[0].querySelectorAll('span');
    const right_number = this.number_box[1].querySelectorAll('span');

    this.progress_timeline != null ? this.progress_timeline.kill() : '';
    this.progress_timeline = gsap.timeline({});

    this.progress_timeline
      .to(
        left_number,
        {
          y: `${this.active_card * -100}%`,
        },
        'same'
      )
      .to(
        right_number,
        {
          y: `${next_active * -100}%`,
        },
        'same'
      )
      .fromTo(
        this.progress,
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          transformOrigin: 'left',
          duration: 5,
          ease: 'none',
        },
        'same'
      );
  }
}
