<template>
  <main id="home" class="relative z-10 w-full min-h-screen">
    <div class="relative z-10 w-full">
      <img
        src="/img/logo_head.svg"
        width="200"
        height="200"
        alt="牛王排氣管"
        class="absolute top-1/2 opacity-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[-1] block max-w-[80%] w-[768px]"
      />
      <Carousel ref="Carousel" />
    </div>
    <!-- <div class="w-full max-w-screen-xl py-20 mx-auto">
      <router-link to="/news/18" class="block w-full px-5 xl:px-0 md:px-10">
        <img src="/img/event_banner_lg.webp" class="hidden w-full md:block" />
        <img src="/img/event_banner_sm.webp" class="block w-full md:hidden" />
      </router-link>
    </div> -->
    <template v-if="data_loaded">
      <ProductsPCSection ref="ProductsPCSection" class="hidden md:block" />
      <ProductsMBSection ref="ProductsMBSection" class="block md:hidden" />
      <GallerySection ref="GallerySection" />
      <div ref="ParallaxContent" class="relative w-full overflow-hidden">
        <NewsListSection
          ref="NewsListSection"
          :news_data="news_data"
          class="hidden md:block"
        />
        <NewsListMBSection
          ref="NewsListMBSection"
          :news_data="news_data"
          class="block md:hidden"
        />
        <AboutSection ref="AboutSection" />

        <div
          class="bg-gradient-to-b from-[#909090] to-[#860000] absolute top-0 left-0 w-full h-full z-[2] mix-blend-overlay"
        ></div>

        <div
          class="absolute top-0 left-0 w-full h-full bg-[#303030] z-[1] opacity-80"
        ></div>

        <img
          data-background
          alt="牛王排氣管"
          class="absolute top-0 left-0 z-0 object-cover w-full h-full transform"
          src="/img/homepage_background.webp"
        />
      </div>
    </template>
  </main>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import ProductsPCSection from "@/components/home/products/Products@lg.vue";
import ProductsMBSection from "@/components/home/products/Products@md.vue";
import GallerySection from "@/components/home/Gallery.vue";
import NewsListSection from "@/components/home/NewsList.vue";
import NewsListMBSection from "@/components/home/NewsList@mb.vue";
import AboutSection from "@/components/home/About.vue";
import { LoadImage } from "@/gsap/image_loaded.js";
// import { parallax_background } from '@/gsap/home/parallax';
export default {
  name: "HomeView",
  components: {
    Carousel,
    ProductsPCSection,
    ProductsMBSection,
    GallerySection,
    NewsListSection,
    AboutSection,
    NewsListMBSection,
  },
  data() {
    return {
      parallax_background: null,
      meta_data: null,
      data_loaded: false,
    };
  },
  methods: {
    SetupGsap() {
      const component_list = [
        this.$refs.Carousel,
        this.$refs.GallerySection,
        this.$refs.AboutSection,
      ];

      if (window.innerWidth > 768) {
        component_list.push(this.$refs.ProductsPCSection);
        component_list.push(this.$refs.NewsListSection);
      }
      if (window.innerWidth <= 768) {
        component_list.push(this.$refs.ProductsMBSection);
        component_list.push(this.$refs.NewsListMBSection);
      }

      NewsListMBSection;

      component_list.forEach((item) => {
        if (item.SetGsap) {
          item.SetGsap();
        }
      });
      this.meta_data = this.$GetMetaData("", "", "");
      this.$nextTick(() => {
        this.$PageReady(this.meta_data.title, "瀏覽首頁");
      });
    },
    CheckDataLoaded() {
      if (this.news_data != null) {
        this.data_loaded = true;
        this.$nextTick(() => {
          LoadImage();
        });
      }
    },
  },
  watch: {
    page_image_loaded() {
      this.page_image_loaded ? this.SetupGsap() : "";
    },
    news_data(new_val, old_val) {
      old_val == null ? this.CheckDataLoaded() : "";
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
    news_data() {
      return this.$store.state.news_data;
    },
  },
  created() {
    this.news_data != null
      ? this.CheckDataLoaded()
      : this.$store.dispatch("GetNews");
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
