import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueMeta from "vue-meta";
import "./common/global_methods";
import VueLazyload from "@/common/vue-lazyload.esm.js";

Vue.use(VueLazyload);

Vue.config.productionTip = false;
Vue.prototype.$MoneyFormat = (price) => {
  let val = (price / 1).toFixed(0).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
