<template>
  <div
    ref="MainContent"
    class="fixed top-0 z-30 flex items-center justify-center w-full h-full left-full"
  >
    <div data-dialog-box class="w-11/12 max-w-[600px] relative z-10">
      <div class="relative z-10">
        <router-link
          to="/news/18"
          @click.native="Close"
          class="block w-full mb-5"
        >
          <img src="/img/event_banner_sm.webp" class="block w-full" />
        </router-link>

        <div class="w-full text-center">
          <button
            @click="Close"
            class="relative inline-block px-10 py-3 text-white transition-all duration-300 border border-white hover:md:bg-white hover:md:text-black"
          >
            關閉
          </button>
        </div>
      </div>
      <div
        class="absolute top-0 left-0 z-0 w-full h-full noise_bg opacity-40"
      ></div>
    </div>
    <div
      data-dialog-bg
      @click="Close"
      class="absolute top-0 left-0 z-0 w-full h-full bg-black bg-opacity-60"
    ></div>
  </div>
</template>

<script>
import { dialog_animation } from "@/gsap/dialog";
export default {
  name: "EventImageDialog",
  data() {
    return {
      close_btn_hover: false,
      dialog_animation: null,
    };
  },
  methods: {
    Close() {
      this.$store.commit("SetEventImageDialog", false);
    },
  },
  watch: {
    dialog() {
      this.dialog
        ? this.dialog_animation.open()
        : this.dialog_animation.close();
    },
  },
  computed: {
    dialog() {
      return this.$store.state.event_image_dialog;
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
  },
};
</script>
