<template>
  <router-link
    :to="link"
    @mouseenter.native="hover = true"
    @mouseleave.native="hover = false"
    class="relative flex items-center justify-start px-5 py-2 sm:py-5"
  >
    <p
      :class="hover ? 'text-white scale-110' : 'text-white text-opacity-80'"
      class="relative z-20 text-lg font-bold transition-all duration-300 transform"
    >
      {{ text }}
    </p>
    <span
      :class="
        hover
          ? 'translate-x-2 -translate-y-12 text-light-red text-opacity-80'
          : ' text-light-red text-opacity-100'
      "
      class="absolute z-[1] right-5 top-0 text-8xl -translate-y-10 transition-all duration-300 transform icon-arrow_right_up"
    ></span>

    <div class="absolute top-0 left-0 z-0 w-full h-full">
      <div
        class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat button_line_bg default"
        :class="hover ? 'opacity-0' : 'opacity-100'"
      ></div>
      <div
        class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat bg_line_animate button_line_bg dark"
        :class="hover ? 'opacity-100' : 'opacity-0'"
      ></div>
    </div>
  </router-link>
</template>

<style scoped>
.bg_line_animate {
  animation: line_animation 1s infinite linear;
}

@keyframes line_animation {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 48px;
  }
}
</style>

<script>
export default {
  name: "MoreButton",
  props: {
    text: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
