import { horizon_scroll } from "@/gsap/horizon_scroll";
import gsap from "gsap";
gsap.config({
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
});
export class carousel_animation extends horizon_scroll {
  constructor(el) {
    super();
    this.el = el;
    this.image_box = el.querySelector("[data-image-wrapper]");
    this.text_box = el.querySelectorAll("[data-text-box]");
    this.image_timeline = null;
    this.top_text_timeline = null;
    this.bottom_text_timeline = null;

    this.trigger_timeline = null;
    this.reset();
  }

  reset() {
    gsap.set(this.image_box, { xPercent: 0 });
  }

  init() {
    this.trigger_timeline != null ? this.trigger_timeline.kill() : "";
    this.trigger_timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        scroller: "#app",
        start: "top bottom",
        end: "bottom top",
        onEnter: () => {
          this.resume();
        },
        onEnterBack: () => {
          this.resume();
        },
        onLeave: () => {
          this.pause();
        },
        onLeaveBack: () => {
          this.pause();
        },
      },
    });
  }

  setTextCarousel() {
    // this.text_timelline != null ? this.text_timelline.kill() : '';
    this.text_timelline = gsap.timeline();
    const top_text = this.text_box[0].querySelectorAll("div");
    const bottom_text = this.text_box[1].querySelectorAll("div");

    this.top_text_timeline = this.setScrollBackward(top_text);
    this.bottom_text_timeline = this.setScrollForward(bottom_text);

    // this.text_timelline.fromTo(
    //   top_text,
    //   {
    //     x: '0%',
    //   },
    //   {
    //     x: '-500%',
    //     duration: 30,
    //     ease: 'none',
    //     repeat: -1,
    //   },
    //   'same_time'
    // );
    // this.text_timelline.fromTo(
    //   bottom_text,
    //   {
    //     x: '-500%',
    //   },
    //   {
    //     x: '0%',
    //     duration: 30,
    //     ease: 'none',
    //     repeat: -1,
    //   },
    //   'same_time'
    // );
  }

  run() {
    const images = this.image_box.querySelectorAll("[data-image-box]");
    this.image_timeline = this.setScrollForward(images);
  }

  pause() {
    this.image_timeline != null ? this.image_timeline.pause() : "";
  }
  resume() {
    this.image_timeline != null ? this.image_timeline.resume() : "";
  }
}
