<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl px-5 pb-40 mx-auto md:px-16 xl:px-0"
  >
    <div class="mb-10 md:mb-20">
      <h3 class="overflow-hidden">
        <span
          data-title
          class="md:text-[100px] sm:text-[80px] text-[50px] font-panchang font-semibold text-red text-opacity-50 block"
          >About Us</span
        >
      </h3>
      <i data-title-line class="w-10 h-[1px] bg-red block mb-4"></i>
      <h4 class="inline-block overflow-hidden">
        <span
          data-title
          class="block font-bold text-white sm:text-xl md:text-2xl"
          >關於我們</span
        >
      </h4>
    </div>
    <div
      data-content
      class="flex flex-wrap items-end justify-between w-full md:flex-nowrap"
    >
      <PCMoreButton
        text="了解更多"
        link="/about"
        class="flex w-full mb-20 md:hidden"
      />
      <div class="flex-1 w-full text-white md:max-w-[66.6666%]">
        <b class="font-panchang">BullKing-Exhaust</b>
        牛王排氣管，成立於2014年。長年專注於開發高品質高性能的排氣管產品，並針對不同面向的玩車人進行不同產品的開發，希望讓每個喜愛玩車的人都能擁有一支適合自己的牛王排氣管。<br /><br />
        產品製造皆由經驗豐富的專業技師使用最專業、最頂尖的製造技術製作，製造過程不放過任何一點小細節並且對產品品質嚴格控管，一切都是為了讓喜歡我們的消費者能以此為榮。<br /><br />
        不管是想要高調亮眼或是低調內斂，不管是大改萬轉引擎還是原廠動力引擎，牛王一定都有最適合你的排氣管。選擇牛王，享受更純粹的玩車樂趣。
      </div>
      <MBMoreButton text="了解更多" link="/about" class="hidden md:flex" />
    </div>
  </section>
</template>

<script>
import MBMoreButton from '@/components/home/about/MoreButton@sm.vue';
import PCMoreButton from '@/components/home/about/MoreButton@lg.vue';
import { about_animation } from '@/gsap/home/about';
export default {
  name: 'AboutSection',
  components: {
    MBMoreButton,
    PCMoreButton,
  },
  data() {
    return {
      about_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.about_animation = new about_animation(this.$refs.MainContent);
    },
  },
};
</script>
