import gsap from 'gsap';

export class main_menu_animation {
  constructor(el) {
    this.el = el;
    this.background = el.querySelectorAll('[data-background]');
    this.links = el.querySelectorAll('[data-link]');
    this.header = el.querySelectorAll('[data-header]');
    this.footer = el.querySelectorAll('[data-footer]');
    this.timeline = null;
  }

  open() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline
      .set(
        this.el,
        {
          x: '-100%',
        },
        'first'
      )
      .fromTo(
        this.background,
        {
          y: '100%',
        },
        {
          y: '0%',
          duration: 0.5,
        },
        'second'
      )
      .fromTo(
        this.header,
        {
          y: '-100%',
          opacity: 0,
        },
        {
          y: '0%',
          opacity: 1,
        },
        'third'
      )
      .fromTo(
        this.footer,
        {
          y: '100%',
          opacity: 0,
        },
        {
          y: '0%',
          opacity: 1,
        },
        'third'
      )
      .fromTo(
        this.links,
        {
          y: 50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.3,
          stagger: 0.1,
        },
        'third'
      );
  }

  close() {
    this.timeline != null ? this.timeline.reverse() : '';
  }
}
