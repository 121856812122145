<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl px-5 py-40 mx-auto md:px-16 xl:px-0"
  >
    <div class="">
      <h3 class="overflow-hidden">
        <span
          data-title
          class="md:text-[100px] sm:text-[80px] text-[50px] font-panchang font-semibold text-white text-opacity-20 block"
          >News</span
        >
      </h3>
      <i data-title-line class="w-10 h-[1px] bg-red block mb-4"></i>
      <h4 class="inline-block overflow-hidden">
        <span
          data-title
          class="block font-bold text-white sm:text-xl md:text-2xl"
          >最新消息</span
        >
      </h4>
    </div>

    <div class="relative w-full pt-14">
      <div data-content class="relative z-10 mb-10 -mx-2 md:-mx-5">
        <div
          v-for="(item, item_index) in carousel_news_data"
          :key="`news_data_${item_index}`"
          class="relative flex-shrink-0 w-full px-2 pb-5 select-none"
        >
          <router-link
            :to="`/news/${item.news_id}`"
            class="relative z-10 flex flex-col h-full transition-all duration-300"
          >
            <p
              class="mb-0 text-sm transition-all duration-300 font-panchang text-red"
            >
              {{ item.created_at.slice(0, 10) }}
            </p>
            <h4
              class="flex-1 mb-2 text-xl font-bold text-white truncate transition-all duration-300"
            >
              {{ item.title }}
            </h4>
            <i class="w-8 h-[2px] opacity-50 bg-zinc-500"></i>
          </router-link>
        </div>
      </div>

      <MBMoreButton text="最新消息列表" link="/news" class="flex mt-10" />
    </div>
  </section>
</template>

<script>
import { news_list_animation } from '@/gsap/home/mb_news_list';
import MBMoreButton from '@/components/home/about/MoreButton@lg.vue';
export default {
  name: 'NewsListSection',
  components: {
    MBMoreButton,
  },
  props: {
    news_data: {
      require: true,
      type: Array,
    },
  },
  data() {
    return {
      news_list_carousel: null,
      active_index: 0,
    };
  },
  methods: {
    SetActive(index) {
      this.active_index = index;
    },
    CheckActive(index) {
      return (
        this.active_index == index ||
        this.active_index + this.news_data.length == index
      );
    },
    changeCarousel(index) {
      index > this.active_index + this.news_data.length
        ? this.news_list_carousel.next()
        : this.news_list_carousel.prev();
    },
    SetGsap() {
      this.news_list_carousel = new news_list_animation(
        this.$refs.MainContent,
        this.SetActive
      );
    },
  },
  computed: {
    carousel_news_data() {
      return this.news_data.slice(0, 5);
    },
  },
  filters: {
    number(val) {
      return val >= 10 ? val : '0' + val;
    },
  },
};
</script>
