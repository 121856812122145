<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl px-5 py-40 mx-auto md:px-16 xl:px-0"
  >
    <div class="">
      <h3 class="overflow-hidden">
        <span
          data-title
          class="md:text-[100px] sm:text-[80px] text-[50px] font-panchang font-semibold text-white text-opacity-20 block"
          >News</span
        >
      </h3>
      <i data-title-line class="w-10 h-[1px] bg-red block mb-4"></i>
      <h4 class="inline-block overflow-hidden">
        <span
          data-title
          class="block font-bold text-white sm:text-xl md:text-2xl"
          >最新消息</span
        >
      </h4>
    </div>

    <div class="relative w-full pt-14">
      <div
        data-content
        class="relative z-10 flex items-stretch mb-10 -mx-2 md:-mx-5"
      >
        <div
          data-carousel-card
          v-for="(item, item_index) in carousel_news_data"
          :key="`news_data_${item_index}`"
          class="relative flex-shrink-0 w-[90%] px-2 pb-5 select-none md:1/2 sm:w-2/3 md:px-5"
          :class="CheckActive(item_index) ? 'opacity-100' : 'opacity-30'"
        >
          <div
            @click="changeCarousel(item_index)"
            :class="
              CheckActive(item_index) ? 'pointer-events-none z-0' : ' z-30'
            "
            class="absolute top-0 bottom-0 left-0 right-0"
          ></div>
          <router-link
            :to="`/news/${item.news_id}`"
            :class="CheckActive(item_index) ? 'bg-white' : 'bg-transparent'"
            class="relative z-10 flex flex-col h-full p-4 transition-all duration-300 border border-gray"
          >
            <div class="w-full mb-2 overflow-hidden aspect-square">
              <img
                :src="item.image"
                :alt="item.title"
                width="807"
                height="807"
                class="block object-cover w-full h-full"
                style="-webkit-user-drag: none"
              />
            </div>
            <div class="flex items-center mb-3">
              <p
                :class="CheckActive(item_index) ? 'text-black' : 'text-gray'"
                class="mr-5 text-sm transition-all duration-300 font-panchang"
              >
                {{ item.created_at.slice(0, 10) }}
              </p>
              <i
                :class="CheckActive(item_index) ? 'bg-black' : 'bg-gray'"
                class="block flex-1 h-[1px] transition-all duration-300"
              ></i>
            </div>
            <h4
              :class="CheckActive(item_index) ? 'text-black' : 'text-gray'"
              class="flex-1 text-2xl font-bold transition-all duration-300"
            >
              {{ item.title }}
            </h4>
          </router-link>
          <div
            class="absolute bottom-3 left-0 w-full h-2 transform md:px-5 px-2 -translate-x-2 z-[1]"
          >
            <i
              :class="
                CheckActive(item_index)
                  ? 'border-black bg-black'
                  : 'border-gray bg-transparent'
              "
              class="block w-full h-full transition-all duration-300 border-b border-l border-r"
            ></i>
          </div>
          <div
            class="absolute w-2 transform -translate-x-2 md:left-5 left-2 top-3 bottom-5"
          >
            <i
              :class="
                CheckActive(item_index)
                  ? 'border-black bg-black'
                  : 'border-gray bg-transparent'
              "
              class="block w-full h-full transition-all duration-300 border-t border-l"
            ></i>
          </div>
        </div>
      </div>

      <div data-content class="relative z-10 w-full text-center">
        <div class="flex items-center justify-center">
          <p data-number-box class="relative h-5 overflow-hidden text-white">
            <span
              v-for="(item, item_index) in news_data"
              :key="`now_index_${item_index}`"
              class="block text-sm font-medium font-panchang"
            >
              {{ (item_index + 1) | number }}
            </span>
          </p>
          <div
            data-progress-box
            class="md:w-[500px] sm:w-[200px] w-[50vw] relative h-[.5px] bg-opacity-50 bg-white mx-4"
          >
            <i
              data-progress
              class="absolute top-1/2 left-0 transform -translate-y-1/2 h-[1.5px] bg-white w-full scale-x-0"
            ></i>
          </div>
          <p data-number-box class="relative h-5 overflow-hidden text-white">
            <span
              v-for="(item, item_index) in news_data"
              :key="`now_index_${item_index}`"
              class="block text-sm font-medium font-panchang"
            >
              {{ (item_index + 1) | number }}
            </span>
          </p>
        </div>
      </div>

      <div class="absolute top-0 bottom-0 z-0 w-full h-full">
        <div
          data-bg-line
          class="absolute top-0 right-0 z-0 object-cover w-full h-full bg-repeat opacity-50 md:w-2/3 button_line_bg default"
          style="background-size: 900px"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import { news_list_animation } from "@/gsap/home/news_list";
export default {
  name: "NewsListSection",
  props: {
    news_data: {
      require: true,
      type: Array,
    },
  },
  data() {
    return {
      news_list_carousel: null,
      active_index: 0,
    };
  },
  methods: {
    SetActive(index) {
      this.active_index = index;
    },
    CheckActive(index) {
      return (
        this.active_index == index ||
        this.active_index + this.news_data.length == index
      );
    },
    changeCarousel(index) {
      index > this.active_index + this.news_data.length
        ? this.news_list_carousel.next()
        : this.news_list_carousel.prev();
    },
    SetGsap() {
      this.news_list_carousel = new news_list_animation(
        this.$refs.MainContent,
        this.SetActive
      );
    },
  },
  computed: {
    carousel_news_data() {
      return []
        .concat(this.news_data)
        .concat(this.news_data)
        .concat(this.news_data);
    },
  },
  filters: {
    number(val) {
      return val >= 10 ? val : "0" + val;
    },
  },
};
</script>
