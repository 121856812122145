import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export class buy_action_animation {
  constructor(el) {
    this.el = el;
    this.title = this.el.querySelectorAll('[data-title]');
    this.title_line = this.el.querySelector('[data-title-line]');
    this.content = this.el.querySelector('[data-content]');
    this.timeline = null;
    this.init();
  }

  init() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 60%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline
      .fromTo(
        this.title[0],
        {
          y: '105%',
        },
        {
          y: '0%',
        },
        'same'
      )
      .fromTo(
        this.title_line,
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          transformOrigin: 'left',
        },
        'same'
      )
      .fromTo(
        this.title[1],
        {
          y: '105%',
        },
        {
          y: '0%',
        },
        'same'
      )
      .fromTo(
        this.content,
        {
          opacity: 0,
          y: '10%',
        },
        {
          opacity: 1,
          y: '0%',
          delay: 0.4,
        },
        'same'
      );
  }
}
