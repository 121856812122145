import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
//ScrollTrigger.normalizeScroll(true);
// gsap.config({
//   force3D: true,
//   nullTargetWarn: false,
//   trialWarn: false,
// });
gsap.registerPlugin(ScrollTrigger);

export class products_animation {
  constructor(el) {
    this.el = el;
    this.title_line = el.querySelector('[data-title-line]');
    this.title = el.querySelectorAll('[data-title]');
    this.product_cards = el.querySelectorAll('[data-product-card]');
    this.content_box = el.querySelectorAll('[data-content-box]');
    this.content = el.querySelectorAll('[data-content]');
    this.bg_line = el.querySelector('[data-bg-line]');
    this.bg_text = el.querySelector('[data-bg-text]');
    this.button_list = el.querySelector('[data-button-list]');
    this.timeline = null;
    this.scrub_timeline = null;
    this.card_timeline = null;
    this.active_index = 0;
    this.init();
  }

  reset() {
    // const all_product_image = this.el.querySelectorAll('[data-product-image]');
    // const all_background_image = this.el.querySelectorAll(
    //   '[data-background-image]'
    // );
    // const all_background_border = this.el.querySelectorAll(
    //   '[data-background-border]'
    // );
    // gsap.set(all_product_image, {
    //   // x: '-50%',
    //   // y: '-50%',
    //   opacity: 0,
    // });
    // gsap.set(all_background_image, { opacity: 0 });
    // gsap.set(all_background_border, {
    //   opacity: 0,
    //   x: 0,
    //   y: 0,
    // });
    // gsap.set(this.content, { x: '50%', opacity: 0 });
    // gsap.set(this.title_line, { scaleX: 0, transformOrigin: 'left' });
    // gsap.set(this.title, { y: '105%' });
    // gsap.set(this.bg_line, { backgroundPositionX: 0 });
    // gsap.set(this.bg_text, { x: '105%' });
  }

  getCardObject(index) {
    const product_image = this.product_cards[index].querySelectorAll(
      '[data-product-image]'
    );
    const background_image = this.product_cards[index].querySelectorAll(
      '[data-background-image]'
    );
    const background_border = this.product_cards[index].querySelectorAll(
      '[data-background-border]'
    );
    return {
      product_image: product_image,
      background_image: background_image,
      background_border: background_border,
    };
  }

  init() {
    const all_product_image = this.el.querySelectorAll('[data-product-image]');
    // this.reset();

    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 80%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline
      .fromTo(
        this.title[0],
        {
          y: '105%',
        },
        {
          y: '0%',
        },
        'first'
      )
      .fromTo(
        this.title_line,
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          transformOrigin: 'left',
        },
        'first'
      )
      .fromTo(
        this.title[1],
        {
          x: '-105%',
        },
        {
          x: '0%',
        },
        'first'
      )
      .fromTo(
        all_product_image,
        { opacity: 0, y: '30%' },
        { opacity: 1, y: '0%' },
        'first'
      );
  }

  changeCard(next_index) {
    const all_background_image = this.el.querySelectorAll(
      '[data-background-image]'
    );
    const all_background_border = this.el.querySelectorAll(
      '[data-background-border]'
    );
    const all_product_image = this.el.querySelectorAll('[data-product-image]');
    const next_card_data = this.getCardObject(next_index);
    // this.card_timeline != null ? this.card_timeline.kill() : '';
    this.card_timeline = gsap.timeline();
    // now card out
    this.card_timeline
      .to(
        this.content,
        {
          opacity: 0,
          x: '-100px',
        },
        'out'
      )
      .to(
        all_background_image,
        {
          opacity: 0,
          // webkitFilter: 'blur(10px)',
          duration: 0.6,
        },
        'out'
      )
      .to(all_background_border, { opacity: 0, x: 0, y: 0 }, 'out')
      .to(all_product_image, { y: '30%', opacity: 0 }, 'out');
    // new card in
    this.card_timeline
      .set(
        this.product_cards,
        {
          x: next_index * -100 + '%',
        },
        'change'
      )
      .set(
        this.content_box,
        {
          x: next_index * -100 + '%',
        },
        'change'
      )
      .to(
        this.content[next_index],
        {
          opacity: 1,
          x: '0px',
        },
        'in'
      )
      .to(
        next_card_data.background_image,
        {
          opacity: 1,
          // webkitFilter: 'blur(0px)',
          duration: 0.6,
        },
        'in'
      )
      .to(
        next_card_data.background_border,
        { opacity: 0.2, x: -16, y: 16 },
        'in'
      )
      .to(
        next_card_data.product_image,
        {
          y: '0%',
          opacity: 1,
          onComplete: () => {
            // set next index
            this.active_index = next_index;
          },
        },
        'in'
      );
  }
}
