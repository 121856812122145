<template>
  <div
    ref="MainContent"
    class="fixed top-0 bottom-0 z-50 flex items-center justify-center w-full left-full"
  >
    <div data-dialog-box class="relative z-10 w-[250px] max-w-full">
      <div class="relative w-full mb-5">
        <img
          src="/img/logo_head.svg"
          width="200"
          height="200"
          alt="牛王排氣管"
          :class="animate_status ? 'scooter' : ''"
          class="relative z-10 block w-4/5 mx-auto"
        />

        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-0 flex-shrink-0 block w-5 h-1 mr-5 bg-white left-16 bottom-10"
        ></i>
        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-0 flex-shrink-0 block w-8 h-1 mr-5 bg-white left-20 bottom-24"
        ></i>

        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-0 flex-shrink-0 block w-10 h-1 mr-5 bg-white left-14 bottom-20"
        ></i>

        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-0 flex-shrink-0 block w-10 h-1 mr-5 bg-white left-10 bottom-40"
        ></i>
        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-0 flex-shrink-0 block w-5 h-1 mr-5 bg-white left-5 bottom-32"
        ></i>

        <i
          :class="animate_status ? 'line' : ''"
          class="absolute left-0 z-0 flex-shrink-0 block w-10 h-1 mr-5 bg-white bottom-2"
        ></i>
        <!--  -->
        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-[11] flex-shrink-0 block w-5 h-1 mr-5 bg-white right-16 bottom-5"
        ></i>
        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-[11] flex-shrink-0 block w-8 h-1 mr-5 bg-white right-20 bottom-24"
        ></i>

        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-[11] flex-shrink-0 block w-10 h-1 mr-5 bg-white right-14 bottom-14"
        ></i>

        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-[11] flex-shrink-0 block w-10 h-1 mr-5 bg-white right-10 bottom-32"
        ></i>
        <i
          :class="animate_status ? 'line' : ''"
          class="absolute z-[11] flex-shrink-0 block w-5 h-1 mr-5 bg-white right-5 bottom-10"
        ></i>

        <i
          :class="animate_status ? 'line' : ''"
          class="absolute right-0 z-[11] flex-shrink-0 block w-10 h-1 mr-5 bg-white bottom-44"
        ></i>
      </div>
      <p
        class="relative z-10 text-xl font-bold text-center text-white font-panchang"
      >
        LOADING
      </p>
    </div>
    <div
      data-dialog-bg
      class="absolute top-0 left-0 z-0 w-full h-full bg-black bg-opacity-50"
    ></div>
  </div>
</template>

<style scoped>
.scooter {
  animation: ride 0.4s infinite linear;
}
.line {
  animation: line 0.6s infinite linear;
}

.line:nth-of-type(1) {
  animation-duration: 1s;
}
.line:nth-of-type(2) {
  animation-duration: 2s;
}
.line:nth-of-type(3) {
  animation-duration: 0.6s;
}
.line:nth-of-type(4) {
  animation-duration: 0.4s;
}
.line:nth-of-type(5) {
  animation-duration: 0.8s;
}

.line:nth-of-type(7) {
  animation-duration: 1s;
}
.line:nth-of-type(6) {
  animation-duration: 2s;
}
.line:nth-of-type(9) {
  animation-duration: 0.6s;
}
.line:nth-of-type(8) {
  animation-duration: 0.4s;
}
.line:nth-of-type(10) {
  animation-duration: 0.8s;
}

@keyframes line {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  50% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes ride {
  0%,
  100% {
    opacity: 1;
    transform: scaleY(1) translateY(0px);
  }

  50% {
    opacity: 1;
    transform: scaleY(0.96) translateY(5px);
  }
}
</style>

<script>
import { dialog_animation } from "@/gsap/dialog";
export default {
  name: "MainLoading",
  data() {
    return {
      dialog_animation: null,
      timer: null,
      animate_status: false,
    };
  },
  computed: {
    main_loading() {
      return this.$store.state.main_loading;
    },
  },
  watch: {
    main_loading(new_val, old_val) {
      this.timer = clearTimeout(this.timer);
      if (new_val > 0 && old_val == 0) {
        this.dialog_animation.open();
        this.animate_status = true;
      } else if (new_val == 0) {
        this.timer = setTimeout(() => {
          this.dialog_animation.close();
          this.animate_status = false;
        }, 1000);
      }
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
  },
};
</script>
