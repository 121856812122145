import Vue from "vue";
import Vuex from "vuex";

import { GetCustomizeData, GetDealers, GetNews } from "@/api/page";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    event_image_dialog: false,
    main_menu: false,
    page_image_loaded: false,
    main_loading: 0,
    main_dialog: {
      msg: "",
      status: false,
    },
    image_dialog: {
      status: false,
      image: "",
    },
    payment_list: [
      {
        id: 6,
        title: "貨到付款",
        type: "cod",
        installment: false,
      },
      {
        id: 7,
        title: "波波零卡分期(分期零利率)",
        type: "BOBOPAY",
        installment: true,
        installment_list: {
          3: 0,
          6: 0,
          9: 0,
          12: 0,
        },
      },
      {
        id: 5,
        title: "超商代碼繳費",
        type: "cvs",
        installment: false,
      },
      {
        id: 4,
        title: "ATM轉帳",
        type: "atm",
        installment: false,
      },
      {
        id: 1,
        title: "中租分期(分期零利率)",
        type: "zero_card",
        installment: true,
        installment_list: {
          3: 0,
          6: 0,
          12: 0,
          18: 0,
          24: 0,
        },
      },
      {
        id: 2,
        title: "信用卡付款",
        type: "credit",
        installment: false,
      },
      {
        id: 3,
        title: "信用卡分期付款(限定台新/玉山)",
        type: "credit_installment",
        installment: true,
        installment_list: {
          3: 0.03,
          6: 0.035,
          12: 0.07,
          18: 0.09,
          24: 0.12,
        },
      },
    ],
    product_list: null,
    scooter_list: null,
    front_option_list: null,
    case_option_list: null,
    power_option_list: null,
    ring_option_list: null,
    hanger_option_list: null,
    paint_option_list: null,
    dealer_data: null,
    news_data: null,
  },
  getters: {},
  mutations: {
    SetEventImageDialog(state, action) {
      state.event_image_dialog = action;
    },
    SetMenu(state, action) {
      state.main_menu = action;
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action;
    },
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetMainDialog(state, { msg, action }) {
      state.main_dialog.msg = msg;
      state.main_dialog.status = action;
    },
    SetStateData(state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {
    GetProductData({ commit }) {
      GetCustomizeData().then((res) => {
        let case_option_list = res.data.case_options.data;
        case_option_list.forEach((item, item_index) => {
          if (item.id == 6) {
            case_option_list.splice(item_index, 1);
          }
        });
        commit("SetStateData", {
          key: "case_option_list",
          value: case_option_list,
        });
        commit("SetStateData", {
          key: "front_option_list",
          value: res.data.front_options.data,
        });
        commit("SetStateData", {
          key: "hanger_option_list",
          value: res.data.hanger_options.data,
        });
        commit("SetStateData", {
          key: "power_option_list",
          value: res.data.power_options.data,
        });
        commit("SetStateData", {
          key: "product_list",
          value: res.data.products.data,
        });
        commit("SetStateData", {
          key: "ring_option_list",
          value: res.data.ring_options.data,
        });
        commit("SetStateData", {
          key: "scooter_list",
          value: res.data.scooters.data,
        });
        commit("SetStateData", {
          key: "paint_option_list",
          value: res.data.paint_options.data,
        });
      });
    },
    GetDealers({ commit }) {
      GetDealers().then((res) => {
        commit("SetStateData", { key: "dealer_data", value: res.data });
      });
    },
    GetNews({ commit }) {
      GetNews().then((res) => {
        commit("SetStateData", { key: "news_data", value: res.data });
      });
    },
  },
  modules: {},
});
