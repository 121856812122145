/* eslint-disable no-useless-escape */
import { get, post_form, zero_card_post } from "@/api/request";
// import case_options from "@/assets/config/case_option.json";
// import front_options from "@/assets/config/front_option.json";
// import hanger_options from "@/assets/config/hanger_option.json";
// import power_options from "@/assets/config/power_option.json";
// import products from "@/assets/config/product.json";
// import ring_options from "@/assets/config/ring_option.json";
// import scooters from "@/assets/config/scooter.json";
// import paint_options from "@/assets/config/paint_option.json";

function getCookie(name) {
  function escape(s) {
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
  }
  var match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
  );
  return match ? match[1] : null;
}

export function GetCustomizeData() {
  // return new Promise((resolve) => {
  //   resolve({
  //     status: "success",
  //     msg: "",
  //     data: {
  //       case_options: case_options,
  //       front_options: front_options,
  //       hanger_options: hanger_options,
  //       power_options: power_options,
  //       products: products,
  //       ring_options: ring_options,
  //       scooters: scooters,
  //       paint_options: paint_options,
  //     },
  //   });
  // });
  return get("customize/get");
  //   array('status' => "success", 'msg' => "", "data" => [
  //     "case_options"   => json_decode($case_options, true),
  //     "front_options"  => json_decode($front_options, true),
  //     "hanger_options" => json_decode($hanger_options, true),
  //     "power_options"  => json_decode($power_options, true),
  //     "products"       => json_decode($products, true),
  //     "ring_options"   => json_decode($ring_options, true),
  //     "scooters"       => json_decode($scooters, true),
  //     "paint_options"  => json_decode($paint_options, true),
  // ])
}

export function CreateTrade(product_data, scooter, user_data, total_price) {
  let form_data = new FormData();
  form_data.append("user_data", JSON.stringify(user_data));
  form_data.append("product_data", JSON.stringify(product_data));
  form_data.append("scooter", scooter);
  form_data.append("total_price", total_price);
  if (getCookie("_fbp")) {
    form_data.append("fbp", getCookie("_fbp"));
  }
  if (getCookie("_fbc")) {
    form_data.append("fbc", getCookie("_fbc"));
  }
  return post_form("customize/create", form_data);
}

export function CreateZeroCardTrade(header_info, trade_info) {
  return zero_card_post(
    "https://api.chaileaseholding.com/api_zero_card/payments/reserve_ec",
    trade_info,
    header_info[Object.keys(header_info)[1]],
    header_info[Object.keys(header_info)[0]]
  );
}

export function ViewDealer(id, area) {
  let form_data = new FormData();
  form_data.append("url", process.env.VUE_APP_BASEURL + "/find_us/" + id);
  form_data.append("area", area);
  if (getCookie("_fbp")) {
    form_data.append("fbp", getCookie("_fbp"));
  }
  if (getCookie("_fbc")) {
    form_data.append("fbc", getCookie("_fbc"));
  }
  //form_data.append("test_code", "TEST82869");
  return post_form("facebook/dealer", form_data);
}

export function ViewPage(path) {
  let form_data = new FormData();
  form_data.append("url", process.env.VUE_APP_BASEURL + path);
  if (getCookie("_fbp")) {
    form_data.append("fbp", getCookie("_fbp"));
  }
  if (getCookie("_fbc")) {
    form_data.append("fbc", getCookie("_fbc"));
  }
  //form_data.append("test_code", "TEST82869");
  return post_form("facebook/view", form_data);
}

export function StartCheckout(product_data) {
  let form_data = new FormData();
  form_data.append("product_data", JSON.stringify(product_data));
  if (getCookie("_fbp")) {
    form_data.append("fbp", getCookie("_fbp"));
  }
  if (getCookie("_fbc")) {
    form_data.append("fbc", getCookie("_fbc"));
  }
  //form_data.append("test_code", "TEST82869");
  return post_form("facebook/start_checkout", form_data);
}

//聯絡我們
export function sendContact(data) {
  let contact_form = new FormData();
  contact_form.append("form_data", JSON.stringify(data));
  return post_form("contact/send", contact_form);
}

// 經銷據點
export function GetDealers() {
  return get("dealer/get");
}

// 最新消息
export function GetNews() {
  return get("news/get");
}
