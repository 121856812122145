import { horizon_scroll } from '@/gsap/horizon_scroll';

export class footer_animation extends horizon_scroll {
  constructor(el) {
    super();
    this.el = el;
    this.text_box = el.querySelectorAll('[data-text-box]');
    this.top_text_timeline = null;
    this.bottom_text_timeline = null;
    // window.innerWidth > 768 ? this.init() : '';
    this.init();
  }

  init() {
    this.top_text_timeline != null ? this.top_text_timeline.kill() : '';
    this.bottom_text_timeline != null ? this.bottom_text_timeline.kill() : '';
    const top_text = this.text_box[0].querySelectorAll('div');
    const bottom_text = this.text_box[1].querySelectorAll('div');

    this.top_text_timeline = this.setScrollBackward(top_text, 1);
    this.bottom_text_timeline = this.setScrollForward(bottom_text, 1);
  }
}
