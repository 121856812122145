<template>
  <router-link
    :to="link"
    @click.native="$emit('click')"
    @mouseenter.native="hover = true"
    @mouseleave.native="hover = false"
    class="relative flex flex-row-reverse items-center justify-end px-2 pb-5 sm:px-5 sm:items-start sm:flex-col"
  >
    <div class="relative pb-2 ml-3 sm:ml-0 sm:mb-2">
      <p
        class="block w-full mb-0 font-light leading-none sm:text-2xl text-light-red font-panchang"
      >
        {{ sub_title }}
      </p>
      <i
        class="inline-block w-5 h-[2px] bg-light-red absolute bottom-0 left-0"
      ></i>
    </div>
    <p
      :class="hover ? 'text-white scale-110' : 'text-white text-opacity-80'"
      class="relative z-20 text-2xl font-bold leading-none transition-all duration-300 transform sm:text-3xl"
    >
      {{ text }}
    </p>
    <span
      :class="
        hover
          ? ' text-light-red text-opacity-80 opacity-100'
          : ' text-light-red text-opacity-100 opacity-0'
      "
      class="absolute z-[1] text-7xl bottom-0 right-2 transition-all duration-300 transform icon-arrow_right_up"
    ></span>

    <div class="absolute bottom-0 left-0 z-0 w-full h-9">
      <div
        class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat button_line_bg default"
        :class="hover ? 'opacity-0' : 'sm:opacity-100 opacity-50'"
      ></div>
      <div
        class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-repeat bg_line_animate button_line_bg dark"
        :class="hover ? 'opacity-100' : 'opacity-0'"
      ></div>
    </div>
  </router-link>
</template>

<style scoped>
.bg_line_animate {
  animation: line_animation 1s infinite linear;
}

@keyframes line_animation {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 48px;
  }
}
</style>

<script>
export default {
  name: "MoreButton",
  props: {
    text: {
      type: String,
    },
    sub_title: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
