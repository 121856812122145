<template>
  <section ref="MainContent" class="relative w-full overflow-hidden">
    <div
      data-text-box
      class="flex md:h-[120px] py-7 w-auto h-[100px] opacity-50 relative z-10"
    >
      <div
        class="h-full px-5"
        v-for="text in 10"
        :key="`text_carousel_top_${text}`"
      >
        <img
          alt="BULLKING 牛王排氣管"
          src="/img/home_carousel/bullking_text.webp"
          width="605"
          height="64"
          class="inline-block w-auto h-full max-w-screen-2xl"
        />
      </div>
    </div>

    <div
      data-image-wrapper
      class="relative z-10 flex items-stretch justify-start w-[200%] py-4"
    >
      <div
        data-image-box
        @mouseenter="PauseCarousel(item_index)"
        @mouseleave="ResumeCarousel"
        class="flex-shrink-0 sm:w-[412px] w-[330px] px-4 relative"
        v-for="(item, item_index) in carousel_gallery_data"
        :key="`gallery_box_${item_index}`"
      >
        <img
          :class="
            hover_image == item_index ? 'grayscale-0 scale-105' : 'grayscale'
          "
          :src="item"
          alt="牛王排氣管 改裝排氣管"
          width="380"
          height="475"
          class="relative z-10 block w-full transition-all duration-300 transform"
        />
        <div
          class="absolute top-0 z-0 h-full transform -translate-x-2 translate-y-2 border border-opacity-50 right-4 left-4 border-gray"
        ></div>
      </div>
      <!-- <div
        data-image-box
        @mouseenter="PauseCarousel(carousel_gallery_data.length)"
        @mouseleave="ResumeCarousel"
        class="flex-shrink-0 sm:w-[412px] w-[330px] px-4 relative"
        :key="`gallery_box_${carousel_gallery_data.length}`"
      >
        <img
          :class="
            hover_image == carousel_gallery_data.length
              ? 'grayscale-0 scale-105'
              : 'grayscale'
          "
          :src="carousel_gallery_data[0]"
          alt="牛王排氣管 改裝排氣管"
          width="380"
          height="475"
          class="relative z-10 block w-full transition-all duration-300 transform"
        />
        <div
          class="absolute top-0 z-0 h-full transform -translate-x-2 translate-y-2 border border-opacity-50 right-4 left-4 border-gray"
        ></div>
      </div> -->
    </div>

    <div
      data-text-box
      class="flex md:h-[120px] py-7 w-auto h-[100px] opacity-50 relative z-10"
    >
      <div
        class="h-full px-5"
        v-for="text in 10"
        :key="`text_carousel_top_${text}`"
      >
        <img
          alt="BULLKING 牛王排氣管"
          src="/img/home_carousel/bullking_text.webp"
          width="605"
          height="64"
          class="inline-block w-auto h-full max-w-screen-2xl"
        />
      </div>
    </div>

    <div
      class="absolute top-0 bottom-0 z-0 w-full max-w-screen-xl transform -translate-x-1/2 left-1/2"
    >
      <div
        data-bg-line
        class="absolute top-0 right-0 z-0 object-cover w-full h-full bg-repeat opacity-50 md:w-2/3 button_line_bg default"
        style="background-size: 900px"
      ></div>
    </div>
  </section>
</template>

<script>
import { carousel_animation } from "@/gsap/home/gallery";
export default {
  name: "HomeGallerySection",
  data() {
    return {
      gallery_data: [
        "/img/home_gallery/7.webp",
        "/img/home_gallery/8.webp",
        "/img/home_gallery/6.webp",
        "/img/home_gallery/2.webp",
        "/img/home_gallery/9.webp",
        "/img/home_gallery/10.webp",
        "/img/home_gallery/11.webp",
        "/img/home_gallery/12.webp",
        "/img/home_gallery/13.webp",
        "/img/home_gallery/14.webp",
        "/img/home_gallery/15.webp",
        "/img/home_gallery/16.webp",
        "/img/home_gallery/3.webp",
        "/img/home_gallery/4.webp",
        "/img/home_gallery/5.webp",
      ],
      carousel_animation: null,
      hover_image: -1,
    };
  },
  methods: {
    PauseCarousel(index) {
      this.carousel_animation.pause();
      this.hover_image = index;
    },
    ResumeCarousel() {
      this.carousel_animation.resume();
      this.hover_image = -1;
    },
    SetGsap() {
      this.carousel_animation = new carousel_animation(this.$refs.MainContent);
      this.carousel_animation.run();
      this.carousel_animation.pause();
      this.carousel_animation.init();
      this.carousel_animation.setTextCarousel();
    },
  },
  computed: {
    carousel_gallery_data() {
      let gallery = [].concat(this.gallery_data);
      window.innerWidth > 768 ? gallery.concat(this.gallery_data) : "";
      return gallery;
      // .concat(this.gallery_data);
    },
  },
};
</script>
